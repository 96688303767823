@use "../../global/_global-dir" as *;

.container-spv {
  width: 100%;
  display: flex;
  flex: 1;
  flex-flow: column nowrap;

  .container-spv-title {
    margin: 1.5rem 0rem;

    h3 {
      font-weight: $font-weight-semibold;
      font-size: 1.25rem;
      line-height: 1.75rem;
      margin: 0rem;
    }
  }
  .container-spv-title-space-name {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    // align-self: flex-end;
    align-self: center;
    font-style: normal;
    font-weight: $font-weight-semibold;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .spv-step-title {
    font-size: 0.875rem;
    line-height: 1.1875rem;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 1.5rem;

    &-bold {
      @extend .spv-step-title;
      font-weight: $font-weight-semibold;
    }
  }

  .content-step {
    margin-top: 2rem;
    flex: 1;
  }

  .client-info-container {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    label {
      color: var(--primary-color);
      font-weight: $font-weight-semibold;
    }

    hr {
      color: var(--primary-color);
      margin: 0
    }
  }

  /*FORM INPUTS*/
  .section-inputs-container {
    display: flex;
    flex-flow: column nowrap;
    flex: 0 0 70%;
    justify-content: center;

    @media only screen and (max-width: 768px) {
      &-final-data {
        flex: 0 0 70%;
      }
    }

    &-final-data {
      display: flex;
      flex-flow: column nowrap;
      flex: 0 0 60%;
      justify-content: center;

      .generic-title .red-line {
        margin-top: 0.5rem;
      }

      // .input-container{
      //   // background-color: var(--paper-color);
      // }
    }
  }

  .input-container {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 1rem;

    .input-adornment {
      color: $silver-chalice;
    }

    .MuiInputAdornment {
      &-positionStart {
        padding-left: 0.875rem;
        margin-right: 0;
      }
    }

    .address-root {
      // .MuiOutlinedInput-root rules;
      // need to be overriden, since this is a <div>
      font-family: $font-family-base;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.4375em;
      color: rgba(0, 0, 0, 0.87);
      box-sizing: border-box;
      position: relative;
      cursor: text;
      display: -webkit-inline-box;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      min-height: 40px;
      padding: 4px 0 5px !important;
      position: relative;
      border-radius: 4px;
      color: var(--text-color) !important;
      min-height: 40px !important;
      text-overflow: ellipsis;
      padding: unset !important;

      .address-input {
        padding-left: 0.75rem;
        width: 100%;
        border: none;
        outline: none;

        &::placeholder {
          color: $silver-chalice;
        }
      }
    }
  }

  .input-label {
    font-weight: $font-weight-semibold;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 0.5rem;

    .custom-tooltip {
      margin-left: 0.5rem;
    }
  }

  .actions-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;

    .button-text {
      margin-right: 0.5rem;
      &-disabled {
        @extend .button-text;
        color: var(--disabled-color);
      }
    }

    .icon {
      &-enabled {
        path {
          fill: white;
        }
      }
      &-disabled {
        path {
          fill: var(--disabled-color);
        }
      }
    }
  }

  .btn-new-simulation {
    @extend .actions-container;
    margin-top: 1rem;

    .tertiary-button svg path {
      fill: var(--primary-color);
    }
  }

  .btn-new-simulation {
    @extend .actions-container;
    margin-top: 1rem;
  }

  .btn-new-simulation {
    @extend .actions-container;
    margin-top: 1rem;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  .MuiAccordion-root.Mui-expanded {
    margin: 0 !important;

    .equipment-icon,
    .expanded-arrow {
      path {
        fill: var(--primary-color);
      }
    }

    .MuiFormControlLabel-label {
      color: var(--text-color);
    }
  }

  .MuiAccordion-root {
    border-bottom: none;
    border-bottom: 1px solid var(--disabled-bg-color);
    box-shadow: none;

    &.MuiPaper-root {
      background-color: unset;
    }
    &::before {
      content: none;
    }
  }

  .equipment-icon {
    margin: auto 0.5rem;
  }
  .no-cursor-pointer {
    cursor: default !important;
  }

  .MuiSelect-select {
    font-size: 0.875rem !important;
  }

  .MuiInputBase-input {
    font-size: 0.875rem !important;
  }

  .MuiInputBase-root {
    background-color: var(--paper-color);
  }

  .space-info-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #3885cd;
    margin-top: 0.75rem;
    
    span {
      font-size: 12px;
    }

    svg {
      width: 14px;
      height: 14px;
      fill: #3885cd;
    }
  }
}

.MuiFormGroup-root[role="radiogroup"] {
  display: inline-flex;
  flex-flow: row;

  .radio-button {
    margin-right: 1.5rem;

    .MuiTypography-body1 {
      font-size: 0.875rem;
    }

    .MuiRadio-root {
      padding-right: 0.5rem;
    }
  }
}

.dialog-title {
  text-align: center;
  font-size: 1.25rem;
  color: var(--primary-color);
  font-weight: $font-weight-normal !important;
  margin-top: 2.375rem;

  .close-icon {
    position: absolute;
    top: 0;
    right: 0;
    // margin: 1rem;
  }
}

.dialog-body {
  font-size: 1rem;
  text-align: center;
  font-weight: $font-weight-normal;
  margin-bottom: 2.375rem;

  &-text {
    color: black !important;
  }
}

.dialog-reset-button {
  .MuiButton-label {
    text-decoration: underline;
    color: $silver-chalice;
  }
}

.dialog-reset-buttons {
  margin-bottom: 2.5rem;
}

.select-arrow-icon {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  color: var(--primary-color);
  width: 24px;
  height: 24px;
  pointer-events: none !important;
}
