@use "../global/_global-dir" as *;

.docs-download-dialog {
  text-align: center;
  font-size: 1.25rem;
  color: var(--primary-color);
  font-weight: $font-weight-normal !important;
  margin-top: 1.375rem;

  .close-icon {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    margin: 0rem;
  }
  p {
    margin: 0rem;
  }
  span {
    font-size: 1rem;
    color: var(--text-color);
  }
}

.docs-list {
  .MuiList-padding {
    padding: 1.6875rem 0rem !important;
  }
}
