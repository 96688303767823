/*Input Styles*/
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 0.875rem;
}

::-moz-placeholder { /* Firefox 19+ */
  font-size: 0.875rem;
}

:-ms-input-placeholder { /* IE 10+ */
  font-size: 0.875rem;
}

:-moz-placeholder { /* Firefox 18- */
  font-size: 0.875rem;
}

legend {
  width: unset !important;
}