.facility-form {
    .section-inputs-container{
        // width: 100%;
        .MuiOutlinedInput-root {
            background-color: var(--paper-color);
        }
    }

    // form {
    //     display: flex;
    //     flex-flow: row;
    //     align-items: flex-start;
    // }
    
    // .input-container {
    //     width: 50%;
    // }
}
