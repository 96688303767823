@use "../../../global/_global-dir" as *;

.consumption-form {
    > h1 {
        font-style: $font-style-normal;
        font-weight: $font-weight-semibold;
        font-size: 0.875rem;
        line-height: 1.188rem;
        color: var(--primary-color);
        margin-bottom: 1.5rem;
    }
    .input-container {
        .radio-button-unchecked .MuiFormControlLabel-label {
            background-color: white;
        }
        .MuiOutlinedInput-root {
            max-width: 270px;
        }
    }
}
