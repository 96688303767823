@use "../global/_global-dir" as *;

.users-container {
    // .csv-download-button {
    //     // margin-bottom: 1rem;
    //     // align-self: flex-end;
    //     min-width: 10rem;
    //     // height: auto !important;
    //     padding: 0 !important;
    //     cursor: pointer;
    //     height: 100%;

    //     &:hover,
    //     &:active,
    //     &:focus {
    //         background-color: unset !important;
    //     }

    //     .button {
    //         &-enabled {
    //             svg {
    //                 path {
    //                     fill: var(--primary-color);
    //                 }
    //             }
    //         }

    //         &-disabled {
    //             svg {
    //                 path {
    //                     fill: var(--disabled-color);
    //                 }
    //             }
    //         }
    //     }

    //     .button-text {
    //         text-decoration: underline;
    //         font-size: 0.875rem;
    //     }
    // }

    .top-search-bar {
        display: flex;
        flex-flow: row nowrap;
        //margin-bottom: 1rem;
        /*     padding: 0.5rem 15px; */
        height: 55px;

        @media screen and (max-width: 768px) {
            max-width: 720px !important;
        }

        .filters-bar-button {
            span {
                font-size: 0.9rem;
                white-space: nowrap;
                min-width: initial;
            }

            &:hover {
                color: var(--primary-color);
            }
        }

        #outlined-users-search {
            height: 19px;
            padding: 8px 12px;
            min-width: 150px;
            width: 450px;
        }

        .loading-component {
            min-width: 0;
        }

        .MuiTypography-root {
            white-space: nowrap;
        }

        .MuiInputBase-root {
            background-color: var(--paper-color);
        }

        .toggle-filters-button,
        .reset-filters-button {
            min-width: 10rem;
            height: auto !important;
            padding: 0 !important;
            cursor: pointer;

            .cross-icon {
                path {
                    fill: var(--primary-color);
                }
            }

            @media screen and (max-width: 768px) {
                min-width: auto;
            }

            &:hover,
            &:active,
            &:focus {
                background-color: unset !important;
            }

            span {
                text-decoration: underline;
                font-weight: $font-weight-normal;
                font-size: 0.875rem;
                line-height: 1.188rem;
                text-transform: uppercase;

                &:hover,
                &:active,
                &:focus {
                    background-color: unset !important;
                }
            }

            &.menu-item-arrow-up svg {
                transform: rotate(180deg);
            }
        }
    }

    .action-options {
        .actions-dropdown-toggle {
            color: var(--text-color-secondary);
            display: flex;
            width: 1.5rem;
            height: 1.5rem;
            box-sizing: border-box;
            border-radius: 3.5px;
            text-align: center;
            border: -1px solid var(--text-color-secondary) !important;
            background-color: unset;
            padding: 0;
            justify-content: center;

            &:hover,
            &:active,
            &[aria-expanded="true"] {
                svg {
                    rect {
                        stroke: var(--primary-color) !important;
                    }
                    circle {
                        fill: var(--primary-color) !important;
                    }
                }
            }

            &:focus {
                box-shadow: unset !important;
            }
        }

        .drop-menu {
            border: 1px solid var(--primary-color);
            background-color: var(--paper-color);
            position: relative;
        }
        .dropdown-item {
            padding: 0.5rem 1rem;
            z-index: 1;
            text-transform: uppercase;

            .icon-container {
                svg {
                    path {
                        fill: var(--primary-color);
                        stroke: var(--primary-color);
                    }
                }
            }

            &:hover {
                background-color: var(--primary-color);

                h5,
                h6 {
                    color: var(--paper-color) !important;
                }

                svg {
                    path {
                        fill: var(--paper-color);
                        stroke: var(--paper-color);
                    }
                }

                .label {
                    font-weight: $font-weight-semibold;
                    font-size: 0.75rem;
                    margin: 0;
                    flex-grow: 0;
                }

                .value {
                    font-size: 1.125rem;
                }
            }

            &:active {
                background-color: var(--primary-color) !important;
                border-color: var(--primary-color) !important;

                svg path {
                    fill: var(--paper-color);
                }
            }

            &-disabled {
                cursor: auto;
                pointer-events: none;
                // background-color: var(--disabled-color);
                color: var(--disabled-color) !important;

                svg {
                    path {
                        fill: var(--disabled-color);
                    }
                }
                &:hover {
                    background-color: var(--disabled-color);
                }
            }
        }
    }
}

.users-filters-dropdown {
    .users-filter-input {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }

    border-top: none;
    border-bottom: 1px solid $silver-light;
    margin-bottom: 1rem;
    background-color: var(--background-color);
    width: 100%;

    .submit-button-container {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
    }

    label {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    .MuiButton-root {
        padding: 6px 15px;
        font-size: 1rem;
        font-weight: $font-weight-normal;
        height: 40px;
        color: var(--text-color);
        border: 2px solid var(--primary-color);
    }

    .MuiInputBase-root {
        background-color: var(--paper-color);
    }

    .MuiInputBase-input {
        font-size: 0.875rem;
    }

    .multiline-autocomplete {
        .MuiOutlinedInput-root {
            // Outside box
            min-height: 40px !important;
            height: fit-content !important;
        }
        .MuiAutocomplete-inputRoot {
            // Array of options
            padding: 7.5px 39px 7.5px 0.75rem !important;
            display: flex;
            align-items: center;
        }

        .MuiAutocomplete-input {
            // Search placeholder
            padding: 0 !important;
        }
    }

    .selected-chips {
        max-width: 90%;
        display: flex;
        flex-wrap: wrap;

        &:not(:empty) {
            padding-left: 0.75rem;
        }
    }

    .multi-input {
        position: relative;

        .MuiOutlinedInput-root {
            // Outside box
            min-height: 40px !important;
            height: fit-content !important;
            display: flex;
            flex-wrap: wrap;
        }

        input {
            min-width: 20%;
            max-width: 90%;
        }

        &-button {
            position: absolute;
            right: 0;
        }
    }

    .selected-chip {
        border-radius: 3px !important;
        margin-right: 0.5rem !important;
        margin-top: 0.375rem !important;
        margin-bottom: 0.375rem !important;
        height: 25px !important;
        border: 1px solid var(--primary-color);
        background-color: var(--paper-color);
        color: var(--text-color);

        .MuiChip-label {
            // Text of the choice
            padding-right: 0.375rem;
            padding-left: 0.5rem;
            font-size: 0.625rem;
        }

        .MuiIconButton-label {
            width: 8px;
        }

        .MuiChip-deleteIcon {
            // delete X on choice
            margin: 0 0 0 -6px;
        }
    }
}

.generic-dialog {
    .MuiDialog-paper {
        min-width: 750px;
        @media only screen and (max-width: 768px) {
            min-width: 410px;
        }
    }

    .MuiDialogTitle-root {
        padding: 2.125rem 3.75rem 1rem 3.75rem;
    }

    .MuiDialogContent-root {
        padding: 1rem 3.75rem 2rem 3.75rem;
        // overflow-y: hidden;

        @media only screen and (max-width: 768px) {
            padding: 1.5rem;
        }
    }

    .MuiDialogActions-root {
        padding: 0;
        margin-bottom: 2.5rem;
    }

    &-header {
        font-weight: $font-weight-semibold;
        font-size: 1rem;
        .red-line {
            height: 3px;
            background-color: #ee162d;
            width: 4.375rem;
        }
    }

    .generic-dialog-close {
        position: absolute;
        right: 0;
        margin: 1.5rem;
        border: none;
    }
}

.process-details {
    display: flex;
    flex-flow: row;
    max-width: 1000px;
    justify-content: space-between;
    overflow: hidden;
    line-height: 21.79px;

    &-header {
        display: inline-block;
        max-width: 100%;
        font-weight: $font-weight-semibold;
        margin-bottom: 3px;
        font-size: 0.875rem;
        color: $silver-chalice;
    }

    &-values {
        font-size: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .process-detail {
        width: 23%;
        margin-right: 0.5rem;
    }

    .process-detail:last-child {
        width: 76%;
        margin-bottom: 0 !important;

        .process-details-values {
            font-size: 0.875rem;
        }
        .process-details-comments {
            width: 590px;
            .MuiInput-underline:before {
                content: unset;
            }

            .Mui-disabled {
                color: var(--text-color);
            }
        }
    }
    .custom-tooltip {
        margin-left: 0.125rem;
        margin-bottom: 0.125rem;
    }

    & .card-kpis {
        height: auto;
        flex: 1;

        .label {
            font-weight: $font-weight-semibold;
            font-size: 0.75rem;
            margin: 0;
            flex-grow: 0;
        }

        .value {
            font-size: 1.125rem;

            .MuiCircularProgress-root {
                height: 22px !important;
                width: 22px !important;
            }
        }
    }

    .generic-dialog-action {
        display: flex;
        align-items: center;
    }
}

.process-clients {
    .process-client {
        position: relative;
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
        max-width: 16.875rem;
        width: 40%;
        margin-right: 1rem;

        &-input {
            @extend .process-client;
            min-height: 5.375rem;
        }

        .process-client-header {
            font-weight: $font-weight-semibold;
            font-size: 0.875rem;
            line-height: 1.188rem;
            margin-bottom: 0.5rem;
        }

        .input-container {
            .Mui-error {
                position: relative;
                bottom: 0rem;
            }
        }
    }
}

.add-user-dialog,
.edit-user-dialog {
    &-header {
        background: #f5f5f5;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
        display: flex;
        flex-flow: row;
        justify-content: space-between;

        h1 {
            font-style: $font-style-normal;
            font-weight: $font-weight-semibold;
            font-size: 1.125rem;
            line-height: 1.563rem;
            display: flex;
            align-items: center;
            color: var(--primary-color);
            margin: 0;
        }
    }

    &-body {
        display: flex;
        flex-flow: column;

        > div {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
        }

        .input-container {
            display: flex;
            flex: 1 0 25%;
            flex-flow: column nowrap;
            margin: 0.5rem;

            .input-label {
                font-style: $font-style-normal;
                font-weight: $font-weight-semibold;
                font-size: 0.875rem;
                line-height: 1.188rem;
                margin: 0.5rem 0;
                align-items: center;
            }

            &.options-radio {
                flex: unset;
            }

            .MuiInputAdornment-positionEnd {
                color: $silver-chalice;
                font-size: 0.875rem;
            }

            .deactived .MuiSelect-select {
                color: $silver-chalice;
                font-size: 0.875rem;
            }
        }

        .MuiAutocomplete-popper {
            margin-top: 71px;
        }

        .autocomplete-bottom-button .MuiAutocomplete-popper {
            .MuiAutocomplete-listbox li:last-of-type {
                position: sticky !important;
                bottom: -0.125rem;
                background-color: var(--paper-color);
                padding: 1rem;
                justify-content: center;
                width: 100%;
                text-transform: uppercase;
                text-decoration: underline;

                &::before {
                    content: url("~/src/assets/images/icons/svg/plus-button-2.svg");
                    position: relative;
                    left: -0.75rem;
                    bottom: -0.1875rem;
                }

                &:hover {
                    color: var(--text-color);
                }
            }
        }

        // .input-container-contracted-energy {
        //     display: flex;
        //     flex-flow: column nowrap;
        //     margin: 0.5rem;

        //     > div {
        //         display: flex;
        //         flex-flow: row;
        //         flex-wrap: wrap;
        //     }
        // }

        // .input-container-monthly-invoice {
        //     display: flex;
        //     flex-flow: row nowrap;
        //     margin: 0.5rem;

        //     .MuiInputAdornment-positionEnd {
        //         color: $silver-chalice;
        //         font-size: 0.875rem;
        //     }
        // }

        // .input-sub-container {
        //     margin-right: 0.75rem;
        //     margin-top: 0.75rem;
        //     display: flex;
        //     flex-flow: column;

        //     .MuiInputLabel-outlined {
        //         font-size: 0.875rem;
        //         transform: translate(14px, 14px) scale(1);
        //     }

        //     .MuiInputLabel-outlined.MuiInputLabel-shrink {
        //         transform: translate(16px, -6px) scale(0.75);
        //         text-transform: uppercase;
        //         color: #202020;
        //     }
        //     .MuiOutlinedInput-notchedOutline legend{
        //         font-size: 0.75rem;
        //         span{
        //             padding: 0 0.5rem;
        //         }
        //     }

        //     .MuiInputAdornment-positionEnd {
        //         color: $silver-chalice;
        //         font-size: 0.875rem;
        //     }
        // }
    }
}

.no-width-limit .content-topnav-container {
    max-width: none !important;
    .forward-button-container {
        padding-right: 1rem;
    }
}
