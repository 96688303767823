@use "../bootstrap.scss" as bootstrap;
@use "../global/_global-dir" as *;

.userData-section {
    background-color: var(--paper-color);
    border-radius: $border-radius-lg;
    box-shadow: $shadow-xl;
    margin-bottom: 2rem;
    padding: 2rem 2rem 2.5rem 2rem;
    font-size: 0.875rem;

    .section-title {
        font-size: 1.25rem;
        font-weight: $font-weight-semibold;
    }

    hr {
        opacity: 1;
        margin: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        width: 70px;
        height: 2px;
        background: var(--primary-color);
    }

    .userData-form {
        .user-section-container {
            padding: 2rem 5.375rem 0 5.375rem;

            @include bootstrap.media-breakpoint-down(md) {
                padding: 2rem 3rem 0 3rem;
            }

            @include bootstrap.media-breakpoint-down(sm) {
                padding: 2rem 2rem 0 2rem;
            }

            .input-container {
                label {
                    margin-bottom: 0.5rem;
                    font-weight: $font-weight-semibold;
                    font-size: 0.875rem;
                    color: var(--text-color);
                    flex: 1 1;
                }

                .MuiOutlinedInput-input {
                    font-size: 0.875rem;
                }
            }
        }
    }
}
