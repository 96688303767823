@use "../global/_global-dir" as *;

.process-table {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  position: relative;
  overscroll-behavior: contain;

  .process-table-row-placeholder {
    .text-block .text-row {
      width: 100% !important;
      height: 5em !important;
    }

    .text-block {
      margin-top: 0.7em;
    }
  }

  .table-hover {
    width: 100%;
    min-width: 100% !important;
    @media screen and (max-width: 768px) {
      min-width: 720px !important;
      min-height: 500px !important;
    }
  }

  .min-resolution-on {
    display: none !important;
  }
  .min-resolution-off {
    display: block !important;
  }

  @media screen and (max-width: 768px) {
    .min-resolution-on {
      display: block !important;
    }
    .min-resolution-off {
      display: none !important;
    }
  }

  .thead {
    width: 100%;
    border-radius: $border-radius;
    border: 1px solid #2020203c;
    background-color: #e5e5e5;
    color: var(--text-color);
    font-weight: $font-weight-semibold;
    min-height: 2.125rem;
    position: sticky;
    top: 0;
    z-index: 2;

    .tr {
      @media screen and (max-width: 768px) {
        min-width: 700px !important;
      }
    }

    .th {
      display: flex;
      align-items: center;
      padding: 0.5rem;
      font-size: 0.875rem;
      font-weight: $font-weight-semibold;
      line-height: 19px;
    }

    .head-scroller {
      width: 100%;
      overflow-x: hidden;
      flex: 1;
    }
  }

  .body-scroller,
  .head-scroller {
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
  }

  .body-scroller {
    position: relative;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    overflow: auto;
    z-index: 1;
    min-height: calc(100vh - 330px - 2rem);
    // max-height: calc(100vh - 280px - 1rem);

    @media screen and (max-width: 768px) {
      min-height: calc(85vh - 220px - 0.5rem);
      // max-height: calc(85vh - 220px - 0.5rem);

      .td {
        align-items: center !important;
      }
    }

    .tr {
      margin: 0.5rem 0;
      border-radius: $border-radius;
      border: 1px solid #e5e5e5;
      background-color: var(--paper-color);
      font-size: 0.875rem;

      @media screen and (max-width: 768px) {
        min-width: 700px !important;
      }
    }

    .td {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 0.5rem;
      min-height: 3.75rem;
    }

    .tr:hover {
      background-color: rgba($black, 0.05);
    }
  }

  .tfooter {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: flex-start;

    @media screen and (max-width: 768px) {
      min-width: 700px !important;
    }
  }

  .process-action-button-email {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    &:hover {
      color: var(--primary-color);

      svg path {
        fill: var(--primary-color);
      }
    }

    &-disabled {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      pointer-events: none;
      color: var(--disabled-color);
      text-decoration: none;
      font-weight: inherit;
      svg path {
        fill: var(--disabled-color);
      }

    }
  }

  .process-action-button-error {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    &:hover {
      color: var(--primary-color);

      svg path {
        fill: var(--primary-color);
      }
    }

    &-disabled {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      text-decoration: none;
      font-weight: inherit;
      cursor: not-allowed;

      &:active {
        color: rgba(0, 0, 0, 0);
        background-color: rgba(0, 0, 0, 0);
        border-color: rgba(0, 0, 0, 0);
      }
    }
  }

  .process-details-button,
  .process-action-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    &:hover {
      color: var(--primary-color);

      svg path {
        fill: var(--primary-color);
      }
    }

    &-disabled {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      pointer-events: none;
      color: var(--disabled-color);
      text-decoration: none;
      font-weight: inherit;
      svg path {
        fill: var(--disabled-color);
      }
    }
  }

  h5 {
    margin: 0;
  }

  .refresh-button {
    height: auto !important;
    padding: 0 !important;
    margin-left: 0.5rem;
    min-width: 0;
  }

  .text-break {
    font-size: 0.875rem;

    @media screen and (max-width: 768px) {
      font-size: 0.75rem;
    }

    &-disabled {
      color: $silver-chalice;
    }
  }

  .bottom-loading {
    display: flex;
    margin-left: 2rem;
    min-width: 50px;

    @media screen and (max-width: 768px) {
      margin-left: 0.5rem;
    }
  }

  .pagination-buttons {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: 0 1rem;
    min-width: 11.25rem;

    @media (max-width: 992px), (max-height: 767.98px) {
      margin: 0 0 0 1.5rem;
    }

    .pagination-text {
      font-size: 0.875rem;
    }

    .pagination-arrow {
      svg {
        path {
          fill: $black;
        }

        line {
          stroke: $black;
        }
      }
      &-disabled {
        svg {
          path {
            fill: var(--disabled-color);
          }

          line {
            stroke: var(--disabled-color);
          }
        }
      }
    }
  }

  // Adds a grayish background to the table body when it is loading
  .table-loading-overlay {
    width: 100%;
    & .tbody {
      pointer-events: none;
      background-color: var(--paper-color) !important;
      overflow: hidden;
    }

    @media screen and (max-width: 768px) {
      min-width: 720px !important;
      min-height: 500px !important;
    }
  }

  .loading-overlay {
    position: absolute;
    height: 10000px; // avoids cutting before end of tbody
    width: 100%;
    top: 0;
    z-index: 9500;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1) !important;
  }

  .update-table-button {
    // @extend .toggle-filters-button;
    height: unset;
    padding: 0px;
    background-color: transparent;
    .MuiButton-text {
      height: unset;
      padding: 0px;
    }

    :hover {
      background-color: transparent;
      svg {
        path {
          stroke: var(--primary-color);
        }

        path:nth-of-type(2) {
          fill: var(--primary-color);
        }

        path:nth-of-type(3) {
          fill: var(--primary-color);
        }
      }
    }

    &-disabled {
      @extend .update-table-button;
      pointer-events: none;
      svg {
        path {
          stroke: var(--disabled-color);
        }

        path:nth-of-type(2) {
          fill: var(--disabled-color);
        }

        path:nth-of-type(3) {
          fill: var(--disabled-color);
        }
      }
    }
  }
}

.no-electric-installation-warning {
  div.MuiTooltip-tooltip {
    background-color: #fef3f4 !important;
    border: 1px solid #f57381;
  }
  .MuiTooltip-arrow::before {
    border: 1px solid #f57381;
  }
}
