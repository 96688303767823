@use "../../global/_global-dir" as *;

.container-me {
    width: 100%;
    display: flex;
    flex: 1;
    flex-flow: column nowrap;

    .container-me-title {
        margin: 1.5rem 0rem;

        h3 {
            font-weight: $font-weight-semibold;
            font-size: 1.25rem;
            line-height: 1.75rem;
            margin: 0rem;
        }
    }
    .container-me-title-typology-name {
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-end;
        // align-self: flex-end;
        align-self: center;
        font-style: normal;
        font-weight: $font-weight-semibold;
        font-size: 0.875rem;
        line-height: 1.25rem;
    }

    .me-step-title {
        font-size: 0.875rem;
        line-height: 1.1875rem;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 2rem;

        &-bold {
            @extend .me-step-title;
            font-weight: $font-weight-semibold;
        }
    }

    .content-step {
        margin-top: 2rem;
        flex: 1;
    }

    .custom-tooltip {
        // max-width: 202px;
        &-blue:hover path {
            fill: #3885cd;
        }
    }

    /*FORM INPUTS*/
    .section-inputs-container {
        display: flex;
        flex-flow: column nowrap;
        flex: 0 0 70%;
        justify-content: center;

        @media only screen and (max-width: 768px) {
            &-final-data {
                flex: 0 0 70%;
            }
        }

        &-final-data {
            display: flex;
            flex-flow: column nowrap;
            flex: 0 0 60%;
            justify-content: center;

            .generic-title .red-line {
                margin-top: 0.5rem;
            }

            // .input-container{
            //   // background-color: var(--paper-color);
            // }
        }
    }

    .input-container {
        display: flex;
        flex-flow: column nowrap;
        margin-bottom: 1.5rem;

        .radio-with-label .radio-button-unchecked .MuiFormControlLabel-label{
            background-color: white;
        }

        .selectable-element {
            margin-top: 0;
            min-width: 156px;
            text-align: center;
        }

        .input-adornment {
            color: $silver-chalice;
        }

        .MuiInputAdornment {
            &-positionStart {
                padding-left: 0.875rem;
                margin-right: 0;
            }
        }

        .slider {
            .MuiSlider-markLabel {
                #valueStart {
                    margin-left: 2rem;
                }
                #valueEnd {
                    margin-right: 2rem;
                }
            }
            .MuiSlider-valueLabel > span > span {
                min-width: 60px;
            }
        }
        .tooltip-info {
            margin-top: 0.5rem;
        }

        .MuiOutlinedInput-root {
            min-height: 40px;

            &.Mui-disabled {
                background-color: rgb(245, 245, 245);
            }

            input {
                // padding: 0;
                // min-height: 40px;
                font-size: 0.875rem;
                // padding: 1rem 12px !important;
            }
        }

        .input-level {
            .MuiOutlinedInput-root {
                width: 50%;
            }
        }

        .radio-button {
            padding-left: 9px;
            .MuiRadio-root {
                padding: 0;
                margin-right: 0.5rem;
            }
        }

        .MuiSelect-select {
            font-size: 0.875rem;

            &:focus {
                background-color: var(--paper-color);
            }
        }
    }

    .input-label {
        font-weight: $font-weight-semibold;
        font-size: 0.875rem;
        line-height: 1.25rem;
        margin-bottom: 0.5rem;

        .custom-tooltip {
            margin-left: 0.5rem;
        }

        .text-bold {
            font-weight: $font-weight-semibold;
        }
    }

    .actions-container {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        margin-top: 2rem;

        .button-text {
            margin-right: 0.5rem;
            &-disabled {
                @extend .button-text;
                color: var(--disabled-color);
            }
        }

        .icon {
            &-enabled {
                path {
                    fill: white;
                }
            }
            &-disabled {
                path {
                    fill: var(--disabled-color);
                }
            }
        }
    }

    .btn-new-simulation {
        @extend .actions-container;
        margin-top: 1rem;
    }

    .btn-new-simulation {
        @extend .actions-container;
        margin-top: 1rem;
    }

    .btn-new-simulation {
        @extend .actions-container;
        margin-top: 1rem;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }

    .MuiAccordion-root.Mui-expanded {
        margin: 0 !important;

        .equipment-icon,
        .expanded-arrow {
            path {
                fill: var(--primary-color);
            }
        }

        .MuiFormControlLabel-label {
            color: var(--primary-color);
        }
    }

    .MuiAccordion-root {
        border-bottom: none;
        border-bottom: 1px solid var(--disabled-bg-color);
        box-shadow: none;

        &.MuiPaper-root {
            background-color: unset;
        }
        &::before {
            content: none;
        }
    }

    .equipment-icon {
        margin: auto 0.5rem;
    }
    .no-cursor-pointer {
        cursor: default !important;
    }

    .MuiSelect-select {
        font-size: 0.875rem !important;
    }

    .MuiInputBase-input {
        font-size: 0.875rem !important;
    }
}

.MuiFormGroup-root[role="radiogroup"] {
    display: inline-flex;
    flex-flow: row;

    .radio-button {
        margin-right: 1.5rem;

        .MuiTypography-body1 {
            font-size: 0.875rem;
        }

        .MuiRadio-root {
            padding-right: 0.5rem;
        }
    }
}

.dialog-title {
    text-align: center;
    font-size: 1.25rem;
    color: var(--primary-color);
    font-weight: $font-weight-normal !important;
    margin-top: 2.375rem;

    .close-icon {
        position: absolute;
        top: 0;
        right: 0;
        // margin: 1rem;
    }
}

.dialog-body {
    font-size: 1rem;
    text-align: center;
    font-weight: $font-weight-normal;
    margin-bottom: 2.375rem;

    &-text {
        color: black !important;
    }
}

.dialog-reset-button {
    .MuiButton-label {
        text-decoration: underline;
        color: $silver-chalice;
    }
}

.dialog-reset-buttons {
    margin-bottom: 2.5rem;
}

.select-arrow-icon {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    color: var(--primary-color);
    width: 24px;
    height: 24px;
    pointer-events: none !important;
}

.popper-blue {
    div.MuiTooltip-tooltip {
        background-color: #f1f8ff !important;
        border: 1px solid #3885cd;
    }
    .MuiTooltip-arrow::before {
        background-color: #f1f8ff !important;
        border: 1px solid #3885cd;
    }
}

.popper-blue-mw {
    @extend .popper-blue;

    max-width: 12.625rem;
    padding: 0.75rem;
}

// Date pickers
.MuiDialog-root {
    .PrivatePickersToolbar-root {
        .MuiTypography-root {
            color: var(--paper-color);
        }
        svg {
            path {
                fill: var(--paper-color);
            }
        }
    }
}