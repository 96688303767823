.equipments-form {
    .equipment-title {
        font-size: 0.875rem;
    }

    .MuiAccordionSummary-content {
        margin: 0 1rem 0  0!important;
    }

    .MuiAccordionDetails-root {
        padding: 0 1rem 0.5rem 1rem;
    }

    .input-label {
        margin-top: 0rem;
    }
}