@use "../../../global/_global-dir" as *;
@use "../../../@efz/_efz-dir" as *;

.current-panels-step {
    display: flex;
    flex-direction: column;
    // HEIGHT = FULL_HEIGHT - ACTIONS_HEIGHT - FOOTER_HEIGHT
    height: calc(100% - 40px - 44px);

    .cpe-section {
        .MuiOutlinedInput-root {
            min-width: 270px;
        }

        &-button {
            font-weight: $font-weight-normal;
            font-size: 1rem;
            line-height: 1.375rem;
            text-transform: uppercase;
            flex-flow: nowrap;
            background: var(--paper-color);
            color: var(--text-color);
            border: 1px solid var(--primary-color);
            box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.08);
            border-radius: 0.313rem;
            padding: 0 1rem;
            height: 40px;
            margin-left: 1.5rem;

            &:hover {
                background-color: var(--paper-color);
            }

            &-disabled {
                @extend .cpe-section-button;
                border: 1px solid var(--disabled-color);
                box-shadow: unset;
            }
        }
    }
    .panels-section {
        padding: 1rem 1.5rem;
        background: #ffffff;
        border: 1px solid #e5e5e5;
        border-radius: 3px;
        max-width: 53.5rem;

        &-noediting {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            &-panels {
                display: flex;
                flex-direction: column;

                .no-editing {
                    &-label {
                        line-height: 19px;
                        font-size: 0.875rem;
                    }
                    &-number {
                        font-size: 1.5rem;
                    }
                    &-disabled {
                        color: var(--disabled-color);
                    }
                }
            }
        }
        &-editing {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            .input-container {
                width: 33.333%;
                padding-right: 1rem;
            }
        }
        &-editing-buttons {
            display: flex;
        }
    }
    .has-battery-warning {
        display: flex;
        border: 1px solid $error-600;
        background-color: #fff3f1;
        border-radius: 3px;
        width: 100%;
        padding: 1rem;
        font-size: 0.875rem;
        max-width: 53.5rem;

        svg {
            margin-right: 0.75rem;
        }
    }
    .icon-button {
        padding: 0;
        svg {
            margin-right: 0.5rem;
        }

        span {
            font-size: 0.875rem;
            line-height: 19.07px;
            text-decoration: underline;
            text-transform: uppercase;
        }

        .edit-button,
        .close-button {
            path {
                fill: var(--primary-color);
            }
            &-disabled {
                path {
                    fill: var(--disabled-color);
                }
            }
        }

        .save-button {
            path {
                fill: var(--primary-color);
                stroke: var(--primary-color);
            }
            &-disabled {
                path {
                    fill: var(--disabled-color);
                    stroke: var(--disabled-color);
                }
            }
        }

        &:hover {
            background-color: transparent;

            span {
                color: var(--primary-color);
            }
        }
    }
}
