@use "../global/_global-dir" as *;

// Each EM card
.opportunities-card-container {
  border: none !important;
  box-shadow: $shadow-lg;
  // height: 22.5rem;
  min-height: 22.5rem;
  background-color: var(--paper-color);
  min-width: 20rem;
  max-width: 23.125rem;

  // Card image container
  .opportunities-card-image-container {
    position: relative;
    display: flex;
  }

  // Card image
  .opportunities-card-banner {
    border: 0 !important;
    border-bottom-left-radius: 0rem;
    border-bottom-right-radius: 0rem;
    min-height: 9.6875rem;
    max-height: 9.6875rem;
    transition: all ease 1s;
  }

  // Bottom part of card
  .opportunities-card-content {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding: 1rem 1.5rem 1.5rem 1.5rem;
  }

  // Card title including icon
  .opportunities-card-title {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-size: 1.3rem;
    margin-bottom: 0.5rem;

    &-icon {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-right: 0.25rem;

      img {
        height: 2.25rem;
        width: 2.25rem;
        margin-right: 0.625rem;
      }
    }

    &-text {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: var(--text-color);
    }
  }
}
