// Variables
//
// All global variables must be declared inside the root element following the pattern
// These variables ae subject to change via JS

:root {
  --primary-color: #ee162d;
  --primary-color-light: #ee162d;
  --primary-color-dark: #b30005;
  --primary-color-bleached: rgba(0, 0, 0, 0);
  --secondary-color: #f1394d;
  --secondary-color-light: #f1394d;
  --secondary-color-dark: #f1394d;
  --tertiary-color: #fff;
  --tertiary-color-light: #fff;
  --tertiary-color-dark: #000;

  --text-color: #000;
  --text-color-secondary: rgba(0, 0, 0, 0.541);
  --background-color: #fafafa;
  --paper-color: #fff;
  --divider-color: #fff;
  --disabled-color: #dcdcdc;
  --disabled-bg-color: #dcdcdc;
}

$gray-500: #8a92a5;

$black: #000;

$app-primary: #ee162d !default;
$primary-color: $app-primary;
$secondary-color: rgb(0, 0, 0);
$danger: #f44336;
$light-gray: #888;
$body-light-gray: #f4f4f7;
$medium-gray: #393939;
$gray-default: #808080 !default;
$silver-chalice: #a3a3a3;
$silver-light: #b1b1b1;

$body-bg: #fafafa;
$body-color: #575757;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1rem;

// Components
//
// Define border radius sizes.
$border-radius-sm: 0.125rem;
$border-radius: 0.375rem;
$border-radius-lg: 0.5rem;
$border-radius-circle: 50% !default;

//Default shadow
$shadow-sm: 0 0px 2px 0 rgba(0, 0, 0, 0.26);
$shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-lg: 0 1px 8px 0px rgba(0, 0, 0, 0.2);
$shadow-xl: 0px 9px 14px 2px rgba(0, 0, 0, 0.07);

// Typography
//
// Font, line-height, and color for body text, headings, and more.
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i");

// stylelint-disable value-keyword-case
$font-family-base: "Open Sans", sans-serif;
// stylelint-enable value-keyword-case

$font-size-base: 0.875rem; // Assumes the browser default, typically `14px`
$font-size-lg: ($font-size-base + 0.25); //18px
$font-size-sm: ($font-size-base - 0.125); //12px

$font-weight-base: 400;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: 800;

$h1-font-size: 1.5rem; //24px
$h2-font-size: 1.25rem; //20px
$h3-font-size: 1.125rem; //18px
$h4-font-size: 1rem; //16px
$h5-font-size: 0.875rem; //14px
$h6-font-size: 0.75rem; //12px

$headings-margin-bottom: calc($h1-font-size / 2);
$headings-font-weight: $font-weight-normal;

// font-style
$font-style-normal: normal;
$font-style-italic: italic;
$font-style-oblique: oblique;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.
$btn-padding: 0.375rem 0.75rem !default;

// Cards
$card-shadow: 0 2px 5px 0 rgba($black, 0.26) !default;
$card-margin: 1.875rem !default;
$card-padding: 30px 150px !default;
$sub-heading-color: $light-gray !default;

//Sizes Variables
$size-10: 10px !default;
$size-20: 20px !default;
$size-30: 30px !default;
$size-40: 40px !default;
$size-50: 50px !default;
$size-60: 60px !default;
$size-70: 70px !default;
$size-80: 80px !default;
$size-90: 90px !default;
$size-100: 100px !default;
$size-120: 120px !default;

//Breakpoints (overrides bootstrap's breakpoints);
//change these values if necessary
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);
