@use "../global/_global-dir" as *;

.custom-tooltip {
    cursor: help;
    &-blue:hover path {
        fill: #3885cd;
    }
}

div.MuiTooltip-tooltip {
    background-color: var(--paper-color);
    border: 0.094rem solid $silver-chalice;
    color: var(--text-color);
    font-size: 0.75rem;
    line-height: 1rem;
    font-style: normal;
    font-weight: normal;
    text-align: center;

    .tooltip-text-justify {
        text-align: justify;
    }
}

.fit-content div.MuiTooltip-tooltip {
    max-width: fit-content;
} //Class added to be used when we need use a image inside a tooltip

.MuiTooltip-arrow {
    &::before {
        color: var(--paper-color);
        border: 0.094rem solid $silver-chalice;
    }
}

.MuiTooltip-popper {
    .email {
        width: 120px;
    }
}
