@use "../global/_global-dir" as *;

/* Style Custom Component Toastify */

.Toastify__toast-container,
.Toastify__toast {
  border-radius: $border-radius !important;
  font-family: inherit !important;
  font-weight: $font-weight-semibold;
}

.Toastify__toast {
  padding: 0.75rem 1rem !important;
}

.Toastify__toast-body {
  color: var(--text-color);
}

.Toastify__close-button {
  color: var(--text-color) !important;
  margin-left: 5px;
}

// States

// SUCCESS
.Toastify__toast--success {
  background: var(--paper-color) !important;
  border: 1px solid #9efaa1;
}

.Toastify__progress-bar--success {
  background: #07bc0c !important;
}

// ERROR
.Toastify__toast--error {
  background: var(--paper-color) !important;
  border: 1px solid #e74c3c;
}

.Toastify__progress-bar--error {
  background: #e47d72 !important;
}

// WARNING
.Toastify__toast--warning {
  background: var(--paper-color) !important;
  border: 1px solid #f1c40f;
}

.Toastify__progress-bar--warning {
  background: #f0d97e !important;
}
