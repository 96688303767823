// Box Shadow
@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  -o-box-shadow: $shadow;
  box-shadow: $shadow;
}

// Flex
@mixin display-flex($flex: flex, $flex-direction: row, $flex-wrap: wrap) {
  display: #{"-webkit"}-#{$flex};
  display: #{"-moz"}-#{$flex};
  display: #{"-ms"}-#{$flex};
  display: #{"-o"}-#{$flex};
  display: $flex;
  -webkit-flex-direction: $flex-direction;
  -ms-flex-direction: $flex-direction;
  flex-direction: $flex-direction;
  -webkit-flex-wrap: $flex-wrap;
  -ms-flex-wrap: $flex-wrap;
  flex-wrap: $flex-wrap;
}

//Mixin Hover

// stylelint-disable indentation
@mixin hover {
  // TODO: re-enable along with mq4-hover-shim
  //  @if $enable-hover-media-query {
  //    // See Media Queries Level 4: https://drafts.csswg.org/mediaqueries/#hover
  //    // Currently shimmed by https://github.com/twbs/mq4-hover-shim
  //    @media (hover: hover) {
  //      &:hover { @content }
  //    }
  //  }
  //  @else {
  &:hover {
    @content;
  }
  //  }
}
