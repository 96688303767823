@use "../global/_global-dir" as global;
@use "../@efz/colors" as *;

.radio-with-label {
    .MuiRadio-root {
        display: none !important;
    }

    .radio-button {
        &-checked {
            margin-left: 0;
            margin-right: 1rem;
            .MuiFormControlLabel-label {
                width: 156px;
                height: 39px;
                border: 1px solid var(--primary-color);
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: var(--primary-color-bleached);
                color: var(--primary-color);
                font-size: 0.875rem !important;
            }
        }

        &-unchecked {
            margin-left: 0;
            margin-right: 1rem;
            .MuiFormControlLabel-label {
                width: 156px;
                height: 39px;
                border: 1px solid $neutral-400;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 0.875rem !important;
            }
        }
    }
}
