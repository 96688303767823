@use "../global/_global-dir" as global;
@use "../@efz/colors" as *;

.counter {
    
    display: flex;
    margin-top: 0.5rem;

    &-minus,
    &-plus {
        width: 1.75rem;
        height: 1.75rem;
        border-radius: 0.1875rem;
        border: solid 0.0625rem var(--primary-color);
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;

        &.disabled {
            border: solid 0.0625rem $neutral-600;
            cursor: not-allowed;
            pointer-events: none;

            div {
                background-color: $neutral-600;
            }

        }

    }

    &-minus {
        div {
            width: 0.5625rem;
            height: 0.0625rem;
            background-color: var(--primary-color);
        }
    }

    &-plus {
        .hor,
        .ver {
            width: 0.5625rem;
            height: 0.0625rem;
            background-color: var(--primary-color);
        }

        .hor {
            position: absolute;
            top: calc(50% - 0.0625rem);
            transform: rotate(90deg);
        }
    }

    &-quantity {
        margin: 0 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Open Sans;
        font-size: 0.875rem;
        font-weight: 400;
    }
}
