/*Base Styles*/
@use "../bootstrap.scss" as bootstrap;
@use "../global/_global-dir" as *;

html {
  height: 100%;
}

:root {
  font-size: 16px;

  @include bootstrap.media-breakpoint-down(sm) {
    font-size: 14px;
  }

  @media (max-height: 767.98px) {
    font-size: 15px;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 10px;
  z-index: 5000;
  background-color: transparent;

  @include bootstrap.media-breakpoint-down(md) {
    height: 5px;
  }
}

::-webkit-scrollbar-thumb {
  background-color: $gray-500;
  border-radius: 10px;
  width: 6px;
  height: 10px;

  @include bootstrap.media-breakpoint-down(md) {
    height: 5px;
  }
}

body {
  font: {
    family: $font-family-base !important;
    size: $font-size-base !important;
    weight: $font-weight-base !important;
  }

  height: 100%;
  overflow: hidden;
  scrollbar-width: thin;
  scrollbar-color: $gray-500 transparent;
}

body.ios-mobile-view-height {
  height: auto !important;
  overflow: visible !important;
}

a {
  color: var(--primary-color);
  text-decoration: none;

  &:hover {
    color: var(--primary-color);
    text-decoration: underline;
  }
}

b {
  font-weight: $font-weight-semibold;
}

/*Typography Styles*/
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: $headings-font-weight;
  margin: 0 0 $headings-margin-bottom;
  color: unset;
  line-height: initial;
}

h2 {
  font-size: 1.25rem;
}
h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 0.875rem;
}

// Top laoding bar
#nprogress .bar {
  z-index: 5000 !important;
  background: var(--secondary-color) !important;
}

#nprogress .peg {
  @include box-shadow(0 0 10px var(--secondary-color), 0 0 5px var(--secondary-color) !important);
}

#nprogress .spinner {
  z-index: 5000 !important;
}

#nprogress .spinner-icon {
  border-top-color: var(--secondary-color) !important;
  border-left-color: var(--secondary-color) !important;
}

.pointer {
  cursor: pointer;
}

.transition-none {
  transition: none !important;
}

.flag {
  font-size: 1.3rem !important;
  height: auto !important;
  box-shadow: 0px 0px 0px 1px var(--divider-color);
}
.disabled-cursor {
  pointer-events: none;
  cursor: default;
}
