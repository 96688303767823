@use "../global/_global-dir" as *;

.default-svg {
  svg {
    path {
      fill: #202020;
    }
  }
  &--disabled {
    svg {
      path {
        fill: var(--disabled-color-icon);
      }
    }
  }
  &--open {
    svg {
      path {
        fill: var(--primary-color);
      }
    }
  }
}

.selected-radio-icon {
  circle {
    &:first-of-type {
      stroke: var(--primary-color);
    }
    &:last-of-type {
      fill: var(--primary-color);
    }
  }
}
