.tooltip-info {
    display: flex;
    flex-flow: row;
    align-items: center;
    background-color: #f1f8ff;
    border: 1px solid #3885cd;
    box-sizing: border-box;
    border-radius: 3px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    padding: 10px 1rem;

    svg path{
        fill: #3885cd;
    }
}

.section-inputs-container{
    // width: 100%;
    .MuiOutlinedInput-root {
        background-color: var(--paper-color);
    }
}
