@use "../global/_global-dir" as *;

.tertiary-button,
.tertiary-button-no-icon {
  height: auto !important;
  padding: 0 !important;
  cursor: pointer;
  text-decoration: underline !important;
  font-weight: $font-weight-normal;
  font-size: 0.875rem;
  line-height: 1rem;
  text-transform: uppercase;

  &:hover,
  &:active,
  &:focus {
    background-color: unset !important;
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.tertiary-button-no-icon {
  margin: 1rem !important;
}

.card-button-default {
  svg path {
    fill: white;
  }
}

.tertiary-button-2 {
  @extend .tertiary-button;

  font-size: 1rem;
}
