@use "../global/_global-dir" as *;

.custom-stepper {
    display: flex;
    flex-flow: row;
}

.custom-step {
    display: inline-flex;
    flex-grow: 1;
    border-bottom: 0.125rem solid var(--disabled-color);
    padding-bottom: 0.75rem;
    padding-left: 0.75rem;
    color: var(--disabled-color);
}

.custom-step-span {
    display: flex;
    margin-left: 0.5rem;
    justify-content: center;
    align-items: center;
}

.custom-step-icon-pencil-active {
    @extend .custom-step-span;

    svg path {
        fill: var(--primary-color);
    }
}

.custom-step-icon-pencil-past {
    @extend .custom-step-icon-pencil-active;
    svg {
        path {
            fill: var(--disabled-color);
        }
    }
}

.custom-step-label {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;

    span {
        margin: 0;
        color: var(--paper-color);
    }
}

.custom-step-active {
    color: var(--primary-color);
    border-color: var(--primary-color);

    svg {
        circle {
            fill: var(--primary-color);
        }
    }
    .custom-step-span {
        color: var(--primary-color);
    }
    .custom-step-label span {
        color: var(--paper-color);
    }
}

.custom-step-past {
    @extend .custom-step-active;
    opacity: 0.5;

    .custom-step-span {
        color: var(--primary-color);
    }
    .custom-step-label span {
        color: var(--paper-color);
    }
}
