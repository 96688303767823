@use "../global/_global-dir" as *;

.discount {
    &-input {
        &.Mui-disabled {
            background-color: #f5f5f5;
        }

        .Mui-disabled {
            cursor: not-allowed;
        }
    }

    &-button {
        font-size: 0.875rem;
        font-weight: $font-weight-semibold;
        color: var(--primary-color);
        height: 43px;
    }

    &-elements {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        column-gap: 0.75rem;
        margin-top: 0.5rem;

        &-chip {
            display: flex;
            align-items: center;
            color: var(--primary-color);
            border: 1px solid var(--primary-color);
            background-color: var(--primary-color-bleached);
            text-transform: uppercase;
            border-radius: 5px;
            padding: 0.5rem 0 0.5rem 0.75rem;

            > span {
                padding: 0;
            }

            > button {
                margin: 0 !important;
                padding-left: 0.5rem;
            }
        }
    }
}
