@use "../global/_global-dir" as global;
@use "../@efz/colors" as *;

.form-input {
    
    &.mtn {
        margin-top: 1rem;
        .MuiInputBase-root {
            margin-top: 0;
        }
    }
    

    .MuiFormHelperText-root {
        position: absolute;
        bottom: -20px;
    }

    margin-top: 1.5rem;

    label {
        // font-family: 'Open Sans';
        font-size: 0.875rem;
        color: $neutral-800;
        font-weight: 600;
    }

    .MuiInputLabel-animated {
        font-size: 1rem;
        top: 0.5rem;
        font-weight: 400;
    }

    .MuiFormGroup-root {
        margin-top: 0.5rem;
    }

    .MuiInputAdornment-positionStart {
        padding: 0.4688rem 0 0.4688rem 0.75rem;
        margin-right: -0.2344rem;
    }

    .MuiInputBase-root {
        margin-top: 0.5rem;
        width: 18.75rem !important;
        height: 2.5rem;
        background-color: white;
    }

    .select .MuiSelect-select {
        // font-family: 'Open Sans';
        font-weight: 500;
        font-size: 0.875rem;
        color: $neutral-800;
    }
    
}
