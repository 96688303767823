@use "../global/_global-dir" as global;
@use "../@efz/colors" as *;

.efz-title {
    h1 {
        font-weight: 600;
        margin: 0 0 0.5rem;
        font-size: 1rem;
        color: $neutral-800;
        letter-spacing: 0.05em;
        text-transform: uppercase;
    }
    hr {
        margin: 0;
        width: 4.375rem;
        height: 0.1875rem;
        background-color: var(--primary-color);
        border: none;
        border-radius: 0.3125rem;
        margin-bottom: 1.5rem;
        opacity: 1;
    }
}

.efz-subtitle {
    h2 {
        font-size: 0.875rem;
        font-weight: 600;
        letter-spacing: 0em;
        color: var(--primary-color);
    }
    hr {
        height: 0.0625rem;
        width: 100%;
        background-color: #E5E5E5;
        margin: 0.5rem 0 1rem;
    }
}
