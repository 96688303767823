@use "../../../global/_global-dir" as *;

// Component SPACE
.content-space {

  .space-title {
    font-size: 0.875rem;
    line-height: 1.1875rem;
    font-weight: $font-weight-normal;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  .container-address-card {
    position: relative;
    width: 21.875rem;
    height: 7.6875rem;
    border-radius: 5px;
    background-color: white;
    font-size: 14px;
    // text-align: center;
    color: grey;
    line-height: 32px;
    overflow: hidden;
    top: 1rem;
    left: 1rem;
    padding: 1rem;
    // media screen
    // @media screen and (max-height: 767.98px) {
    //   width: 19rem;
    // }

    &-draw-info {
      @extend .container-address-card;
      height: auto; // height: 20.5rem !important;
    }
    .address-card {
      font-size: 0.875rem;
      .search-card {
        .text-search {
          @extend .space-title;
          margin: 0 0 1rem 0 !important;
          text-align: left !important;
          font-weight: $font-weight-semibold !important;
          color: var(--text-color);
          text-transform: uppercase;
        }
        .input-autocomplete {
          position: relative;
          margin: 0 !important;

          .pac-target-input {
            height: 2.5rem;
            width: 100%;
            padding: 0.625rem;
            padding-right: 2.5rem;
            font-size: 0.875rem;
            line-height: 1.6875rem;
            border-radius: 3px;
            border: 1px solid $silver-chalice;
            input:focus {
              outline: var(--primary-color);
            }
          }
          .pac-target-input:focus {
            outline: none !important;
            border: 1px solid var(--primary-color);
          }

          &-icon {
            top: 0;
            right: 0;
            position: absolute;
            margin: 0.625rem;
          }
        }
        .search-text-info {
          @extend .text-search;
          text-transform: unset;
          font-size: 0.75rem;
          margin: 0.5rem 0rem !important;
          color: var(--text-color);
        }
      }

      .info-data-card {
        margin: 1rem;
        text-align: center !important;
        .title {
          font-size: 0.875rem;
          font-weight: $font-weight-semibold;
          color: var(--text-color);
          margin-bottom: 0.5rem;
        }
        .subtitle {
          font-size: 0.875rem;
          font-weight: $font-weight-normal;
          color: var(--text-color);
          margin: 0rem;
        }
        .content-available-area {
          font-size: 1.25rem;
          font-weight: $font-weight-semibold;
          line-height: 1.6875rem;
          color: var(--primary-color);
          margin: 1.25rem 0rem;

          span {
            margin-left: 0.125rem;
            font-size: 0.875rem;
            color: var(--text-color);
            line-height: 1.25rem;
          }
        }

        .drawing-icon {
          margin-right: 1rem;
          &-enabled {
            rect {
              fill: #d5c628;
            }
          }
        }

        .button-space {
          border: 1.5px solid;
        }
        .button-skip-step {
          margin: 1rem;
          color: var(--text-color);

          svg {
            path {
              fill: var(--primary-color);
            }
          }
          // media screen
          @media screen and (max-height: 767.98px) {
            margin: 0.5rem;
          }
        }
        .warn-search {
          font-size: 1rem;
          font-weight: $font-weight-semibold;
          color: red;
        }
      }
    }
  }

  // Map section
  .gmap-section {
    position: relative;
    padding: 0;
    transition: all ease 0.5s;
    height: 35rem;

    // media screen
    @media screen and (max-height: 767.98px) {
      height: 25rem;
    }
    @media screen and (max-height: 768px) {
      height: 24rem;
    }

    & > div {
      & > input {
        z-index: 100;
        display: block;
        padding: 0.375rem 0.75rem;
        font-size: 0.875rem;
        font-weight: $font-weight-normal;
        line-height: 1.5;
        color: #495057;
        background-color: var(--paper-color);
        background-clip: padding-box;
        border: 1px solid #ced4da;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        position: absolute;
        top: 10px;
        left: 193px;
        border-radius: 2px;
        height: 40px;
        width: calc(100% - 253px);
      }

      & > div > div {
        border-radius: 0.375em;
      }
    }
  }
  .button-next {
    // margin-top: 2rem;
    margin: 2rem 0rem;
    text-align-last: center;
    // media screen
    @media screen and (max-height: 768px) {
      margin: 1.5rem 0rem;
    }

    .button-text {
      margin-right: 0.5rem;
      &-disabled {
        @extend .button-text;
        color: var(--disabled-color);
      }
    }

    .icon-enabled {
      path {
        fill: white;
      }
    }
  }

  .MuiButtonBase-root {
    display: inline-flex !important;
    -webkit-display: inline-flex !important;
    justify-content: center !important;
    -webkit-justify-content: center !important;
    align-items: center !important;
    -webkit-align-items: center !important;
  }
}
