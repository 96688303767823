@use "../bootstrap.scss" as bootstrap;
@use "../global/_global-dir" as *;

/*Header Styles*/

.app-header {
  @include display-flex(flex, column, nowrap);
  flex-shrink: 0;
  position: sticky;
  top: 0;
  left: auto;
  right: 0;
  width: 100%;
  z-index: 1100;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  min-height: 70px;
  max-height: 70px;
  background-color: var(--paper-color);

  @media (max-width: 575.98px), (max-height: 767.98px) {
    min-height: 60px;
    max-height: 60px;
  }

  .dropdown-menu {
    width: 22rem;
    border-radius: 0;
    padding: 0;
    background-color: var(--paper-color);
    margin: 0;
    border-top: none !important;
    cursor: default;
    top: 1px !important;

    @include bootstrap.media-breakpoint-down(sm) {
      width: 100%;
      border-left: none !important;
      border-right: none !important;
      //transition: all ease 0.5s;
      left: -5px !important;
    }
  }

  > .content-wrapper {
    height: 70px;
  }
}

.dropdown-header-section {
  @include display-flex(flex, row, nowrap);
  padding: 1rem;
  align-items: center;
  border-bottom: 1px solid var(--divider-color);

  .btn-actions {
    margin-top: 0.75rem !important;
  }

  .header-section-text {
    @include display-flex(flex, column, nowrap);
    flex: 1;

    h5 {
      color: var(--text-color);
      font-size: 0.875rem;
    }

    h6 {
      color: var(--text-color-secondary);
      font-size: 0.75rem;
    }

    .clear-selection-button {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-size: 0.75rem;
      color: var(--text-color);
      text-decoration: underline;

      &:hover {
        color: var(--primary-color);
        cursor: pointer;
      }
    }

    .hidde-button {
      visibility: hidden;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-size: 0.75rem;
      color: var(--text-color);

      &:hover {
        color: var(--primary-color);
        cursor: pointer;
      }
    }

    .client-info {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;

      // &:hover {
      //   cursor: pointer;
      // }
    }
  }

  svg {
    fill: var(--primary-color);
    path {
      fill: var(--primary-color);
    }
  }

  &.clickable {
    cursor: pointer;
  }

  &:hover {
    .facility-arrow {
      background-color: var(--paper-color) !important;

      i {
        color: var(--primary-color) !important;
      }
    }
  }
}

.user-card {
  align-items: unset;

  &.dropdown-header-section {
    border-bottom: none;

    &:last-of-type {
      padding-top: 0;
    }
  }

  .tertiary-button {
    margin: 0 !important;
    text-transform: uppercase;

    span {
      font-size: 0.75rem;
    }

    &:hover {
      color: var(--primary-color);
    }
  }
}

.header-logo {
  display: flex;

  a {
    @include display-flex(flex, row, nowrap);
    text-decoration: none;
    color: var(--text-color);
    align-items: center;

    img {
      max-height: 50px;

      @media (max-width: 575.98px), (max-height: 767.98px) {
        max-height: 40px;
      }
    }

    svg {
      max-width: 6rem;

      @media (max-width: 575.98px), (max-height: 767.98px) {
        max-width: 4rem;
      }
    }

    span {
      align-self: center;

      @include bootstrap.media-breakpoint-down(sm) {
        display: none;
      }

      p {
        margin: 0;
        font-size: 1.5rem;
        font-weight: $font-weight-light;

        @include bootstrap.media-breakpoint-down(lg) {
          font-size: 1rem;
        }
      }
    }
  }
}

.header-menu {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
}

ul.menu-list {
  @include display-flex(flex, row, nowrap);
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;

  & li.menu-item {
    display: flex;
    align-items: center;
    margin: 0px;
    list-style: none;

    &-expanded {
      @extend .menu-item;
      background-color: var(--background-color);

      .menu-item-text h6 {
        line-height: 0;
      }
    }

    &:last-child .menu-item-content .menu-item-content-button-form-forward {
      border: none;
    }

    &:hover,
    &:focus {
      background-color: var(--background-color);
      cursor: pointer;

      & span svg:not(.main-header-flag) path {
        fill: var(--primary-color);
      }

      .menu-item-text h5 {
        color: var(--text-color);
      }

      // .menu-item-text h6{
      //   color: var(--primary-color);
      // }
    }

    .dropdown {
      display: flex;
      height: 100%;
    }
  }
}

.header-dropdown-toggle {
  padding: 1rem 0;

  @media (max-width: 575.98px), (max-height: 767.98px) {
    padding: 0.5rem 0;
  }
}

// Displays the icon and text
.menu-item-content {
  @include display-flex(flex, row, nowrap);
  height: 100%;
  padding: 0 1rem;
  align-items: center;
  border-right: 1px solid var(--divider-color);
  // icon
  & > span {
    align-self: center;
  }

  & h5 {
    color: var(--text-color);
    margin: 0;
    font-weight: $font-weight-normal;
    align-self: center;
  }

  &-last {
    @extend .menu-item-content;
    border-right: unset;
  }
}

.menu-item-content-forward {
  @extend .menu-item-content;
  padding: 0rem !important;

  .button-form {
    min-height: 70px;
    max-height: 70px;
    @media (max-width: 575.98px), (max-height: 767.98px) {
      min-height: 60px;
      max-height: 60px;
    }
    text-transform: none;
    padding: 0 1rem !important;
    box-shadow: none;
    // color: none;
    background-color: var(--paper-color);
    border-radius: 0px;

    &:disabled {
      color: none;
      // padding: 0rem 1rem;
      box-shadow: none;
      background-color: var(--paper-color);
    }

    &:active,
    &:hover {
      // padding: 2.1rem 1rem;
      background-color: var(--background-color) !important;
      box-shadow: none;
    }
  }
}

// Text of each dropdown menu item displayed after icon
.menu-item-text {
  display: flex;
  flex-flow: column nowrap;

  @include bootstrap.media-breakpoint-down(md) {
    & > h5 {
      display: none;
    }

    & > h6 {
      display: none;
    }
  }

  .menu-item-label {
    color: var(--text-color);
    margin: 0;
    font-weight: $font-weight-normal;
    align-self: flex-start;

    &--open {
      @extend .menu-item-label;
      color: var(--primary-color);
      font-weight: $font-weight-bold;
    }
    &--disabled {
      @extend .menu-item-label;
      cursor: default;
      color: var(--disabled-color-icon);
    }
  }

  & > h6 {
    // display: inline;
    color: var(--text-color-secondary);
    margin: 0;
    font-weight: $font-weight-normal;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 9rem;
  }

  &:hover {
    color: var(--paper-color) !important;
  }
}

// Arrow of each menu item displayed after icon
.menu-item-arrow {
  color: $silver-light;
  margin-left: 0.5rem;
  margin-right: -0.75rem;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 1.2rem;
    transition: all linear 0.2s;
  }

  &--closed {
    @extend .menu-item-arrow;
  }

  &--open {
    @extend .menu-item-arrow;

    svg {
      transform: rotate(180deg);
    }
  }
}

// languages dropdown
.language-list {
  li {
    padding: 0.6rem 0.5rem 0.6rem 1rem;
    border-bottom: 0.5px solid #c4c4c4;
  }

  li:last-child {
    border-bottom: 0;
  }

  li:hover {
    background-color: var(--primary-color);

    h5 {
      color: var(--paper-color);
    }

    .default-svg--open svg path {
      fill: var(--paper-color);
    }
  }

  h5 {
    color: var(--text-color);
  }
}

// the flag that appears in the header
.main-header-flag {
  font-size: 1.5rem !important;
  height: auto !important;
  box-shadow: 0px 0px 0px 1px var(--divider-color);
}

.dark-mode-icon {
  font-size: 1.3rem;
  color: var(--primary-color);
}
