@use "../../../global/_global-dir" as *;

.common-spaces {
    &-container {
        display: flex;
        width: 100%;
        flex-flow: column nowrap;
        flex: 3 1 400px;
        margin-bottom: 1.5rem;

        &-details {
            display: flex;
            flex-flow: column nowrap;
            flex: 1 1 100%;
            width: 100%;
            background: #f5f5f5;
            border: 1px solid #c4c4c4;
            box-sizing: border-box;
            border-radius: 0.5rem 0.5rem 0 0;
            padding: 1.5rem 1.813rem 1rem 1.813rem;
            min-height: 314px;

            h1 {
                text-transform: uppercase;
                font-style: $font-style-normal;
                font-weight: $font-weight-semibold;
                font-size: 0.875rem;
                line-height: 1.188rem;
                color: $silver-chalice;
            }
        }

        &-footer {
            display: flex;
            background: #ffffff;
            border: 1px solid #c4c4c4;
            box-sizing: border-box;
            box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
            border-radius: 0 0 0.5rem 0.5rem;
            justify-content: space-between;
            padding: 1.375rem 1.813rem;

            span {
                display: flex;
                align-items: center;
            }
        }

        &-total-warning {
            display: flex;
            width: 100%;
            justify-content: center;
            font-style: $font-style-normal;
            font-weight: $font-weight-normal;
            font-size: 0.875rem;
            line-height: 1.188rem;
            text-align: right;
            color: #3885cd;
            margin-top: 1rem;
        }
    }

    &-cards {
        display: flex;
        flex-flow: row;
        margin-top: 1rem;
        font-weight: $font-weight-semibold;
        font-size: 0.875rem;
        line-height: 1.188rem;
        overflow-y: hidden;
        overflow-x: auto;
        padding-bottom: 2rem;

        &::-webkit-scrollbar {
            background: rgba(67, 66, 68, 0.1);
            box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.08);
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #5d656b;
        }

        &-button {
            display: flex;
            flex-flow: column;
            width: 100%;
            justify-content: center;
            align-items: center;

            button:hover {
                background: none;
            }

            span {
                margin-top: 2rem;
            }

            h3 {
                text-transform: uppercase;
            }

            svg {
                rect {
                    fill: var(--primary-color);
                }
            }
        }
    }

    &-card {
        min-width: 230px;
        max-width: 230px;
        padding: 0.938rem 1.063rem;
        background: #ffffff;
        border-radius: 0.5rem;
        margin-right: 1.5rem;
        border: 1px solid #c4c4c4;
        box-sizing: border-box;

        h2 {
            font-style: $font-style-normal;
            font-weight: $font-weight-normal;
            font-size: 1rem;
            line-height: 1.375rem;
            text-align: center;
        }

        &-body {
            margin-top: 0.75rem;
            margin-bottom: 1.25rem;
            div {
                background: #f5f5f5;
                padding: 0.375rem 0.563rem;
            }

            h3 {
                font-style: $font-style-normal;
                font-weight: $font-weight-semibold;
                font-size: 0.625rem;
                line-height: 0.875rem;
                color: $silver-chalice;
                border-bottom: 1px solid rgba(196, 196, 196, 0.5);
                padding-bottom: 0.313rem;
                margin: 0;
            }

            span {
                display: flex;
                padding-top: 0.313rem;
                font-style: $font-style-normal;
                font-weight: $font-weight-normal;
                font-size: 0.625rem;
                line-height: 0.875rem;
            }
        }

        &-footer {
            display: flex;
            flex-flow: row;
            justify-content: space-between;

            :first-child {
                display: flex;
                flex-flow: row;
            }

            .remove-icon {
                path {
                    fill: var(--primary-color);
                }
            }

            button[disabled] {
                svg {
                    cursor: default;
                }

                svg path {
                    fill: var(--disabled-color);
                }
            }
        }
    }
}

.add-common-space-dialog,
.edit-common-space-dialog {
    &-header {
        background: #f5f5f5;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
        display: flex;
        flex-flow: row;
        justify-content: space-between;

        h1 {
            font-style: $font-style-normal;
            font-weight: $font-weight-semibold;
            font-size: 1.125rem;
            line-height: 1.563rem;
            display: flex;
            align-items: center;
            color: var(--primary-color);
            margin: 0;
        }
    }

    &-body {
        display: flex;
        flex-flow: column;
        padding: 0 4rem !important;

        hr {
            width: 600px;
            margin: 0;
            margin-top: 0.5rem;
        }

        > div {
            margin-top: 2rem;
        }

        .add-common-space-subtitle {
            color: var(--primary-color);
            font-weight: $font-weight-semibold;

            &--disabled {
                color: #a3a3a3;
            }
        }

        .inputs-group {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;

            .input-container {
                max-width: 270px;
                margin-right: 1.5rem;
            }
        }

        .input-container {
            display: flex;
            flex: 1 0 25%;
            flex-flow: column nowrap;
            margin: 0.5rem;
            margin-left: 0;

            .input-label {
                font-style: $font-style-normal;
                font-weight: $font-weight-semibold;
                font-size: 0.875rem;
                line-height: 1.188rem;
                margin: 0.5rem 0;
                align-items: center;
            }

            &.options-radio {
                flex: unset;
            }

            .MuiInputAdornment-positionEnd {
                color: $silver-chalice;
                font-size: 0.875rem;
            }

            .deactived .MuiSelect-select {
                color: $silver-chalice;
                font-size: 0.875rem;
            }
        }

        .input-container-contracted-energy {
            display: flex;
            flex-flow: column nowrap;
            margin: 0.5rem;
            margin-left: 0;
            margin-top: 1.5rem;

            > div {
                display: flex;
                flex-flow: row;
                flex-wrap: wrap;
            }
        }

        .input-container-monthly-invoice {
            display: flex;
            flex-flow: row nowrap;
            margin: 0.5rem;
            margin-left: 0;

            .MuiInputAdornment-positionEnd {
                color: $silver-chalice;
                font-size: 0.875rem;
            }
        }

        .input-sub-container {
            margin-right: 0.75rem;
            margin-top: 0.75rem;
            display: flex;
            flex-flow: column;

            .MuiInputLabel-outlined {
                font-size: 0.875rem;
                transform: translate(14px, 14px) scale(1);
            }

            .MuiInputLabel-outlined.MuiInputLabel-shrink {
                transform: translate(16px, -6px) scale(0.75);
                text-transform: uppercase;
                color: #202020;
            }
            .MuiOutlinedInput-notchedOutline legend {
                font-size: 0.75rem;
                span {
                    padding: 0 0.5rem;
                }
            }

            .MuiInputAdornment-positionEnd {
                color: $silver-chalice;
                font-size: 0.875rem;
            }
        }
    }
}
