@use "../../../global/_global-dir" as *;

.goto-collective-facility-dialog {
    h5 {
        // font-weight: $font-weight-semibold;
        font-size: 14px;
        line-height: 22px;
        // color: var(--primary-color);
        padding-bottom: 0.5rem;
        // border-bottom: 1px solid #e5e5e5;
        margin-bottom: 1rem;
    }

    .info-features-box {
        margin-bottom: 1rem;
        column-gap: 2rem;
    }

    .info-feature {
        display: flex;
        flex-flow: column nowrap;
        row-gap: 0.75rem;

        div {
            display: flex;
            align-items: center;
            column-gap: 1rem;
            background: #f5f5f5;
            border: 1px solid #e5e5e5;
            box-sizing: border-box;
            border-radius: 10px;
            padding: 1rem;
            width: 100%;
            height: 3.75rem;

            svg {
                width: 30px;
                justify-content: center;
            }

            span {
                justify-content: flex-start;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
            }
        }
    }

    .info-msg {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 1rem;
    }
}

.collective-info-next-button {
    svg {
        path {
            fill: var(--paper-color) !important;
        }
    }
}

.generic-dialog {
    // max-width: max-content;
    &-body {
        padding: 1rem 3.75rem 1.5rem 3.75rem !important;
    }
}

.generic-dialog-actions {
    .MuiButton-textSecondary:hover {
        background-color: unset;
    }
    .MuiButton-textSecondary {
        color: #202020;
        text-decoration: underline;
    }
}
