@use "../global/_global-dir" as global;
@use "../@efz/colors" as *;

.range-comparision-dialog {
    .MuiPaper-root {
        max-width: 726px;
        width: 726px;
    }
}

.aqs-edetails-dialog {
    .MuiPaper-root {
        max-width: 500px;
        width: 500px;
    }
}

.equipment-card-more-details {
    .MuiPaper-root {
        max-width: 726px;
        width: 726px;
    }

    &-header {
        position: relative;
        padding: 2rem 3.5rem 1.5rem;

        h1 {
            font-family: Poppins;
            font-size: 1rem;
            font-weight: 400;
            color: var(--primary-color);
        }

        hr {
            margin: 0.5rem 0 0;
            color: var(--primary-color-light);
        }

        .close-icon {
            position: absolute;
            top: 0;
            right: 0;
            padding: 1.5rem;
            svg {
                path {
                    fill: var(--primary-color);
                }
            }
        }
    }

    section {
        margin-bottom: 1.5rem;
        h2 {
            font-family: Open Sans;
            font-size: 0.875rem;
            font-weight: 500;
            color: $neutral-600;
            margin-bottom: 0.25rem;
        }
        p {
            font-family: Open Sans;
            font-size: 0.875rem;
            font-weight: 400;
            color: $neutral-800;
            margin-bottom: 0;
        }
    }

    &-content {
        padding: 0 3.5rem 1rem;
        display: grid;
        grid-template-areas:
            "img . . ."
            "mdl . . type"
            "clr . . capacity"
            "dimensions . . temp"
            "installation . . power"
            "details details details details";
        grid-template-rows: 1fr;
        grid-template-columns: 2fr 0.5fr 0.5fr 2fr;
        width: 100%;

        &-image {
            grid-area: img;
            width: 5.25rem;
            height: 5.25rem;
        }
        &-model {
            grid-area: mdl;
        }
        &-type {
            grid-area: type;
        }
        &-color {
            grid-area: clr;
        }
        &-capacity {
            grid-area: capacity;
        }
        &-dimensions {
            grid-area: dimensions;
        }
        &-temperature {
            grid-area: temp;
        }
        &-installation {
            grid-area: installation;
        }
        &-power {
            grid-area: power;
        }
        &-details {
            grid-area: details;
        }
    }
}

.equipment-card {
    width: 16rem;
    height: 20.1875rem;
    margin-top: 28px;
    margin-right: 1.25rem;
    cursor: pointer;
    position: relative;

    border-radius: 0.3125rem;

    &.has-tag {
        border-radius: 0 0 5px 5px;
    }

    border: 1px solid $neutral-200;

    padding: 1rem 0.75rem;

    display: grid;
    grid-template-areas:
        ". img ."
        "details details details"
        "price price price";
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;

    &.selected {
        border: 1px solid var(--primary-color) !important;
        background-color: var(--primary-color-bleached);
    }

    &-header-tag {
        border-radius: 0.625rem 0.625rem 0 0;
        border-top: 1px solid var(--primary-color);
        border-right: 1px solid var(--primary-color);
        border-left: 1px solid var(--primary-color);
        border-bottom: 1px solid var(--primary-color);
        background-color: var(--primary-color-bleached);

        padding: 0.4437rem 0.6512rem 0.3688rem 0.6511rem;

        position: absolute;
        height: 1.75rem;
        top: -1.75rem;
        left: -0.0625rem;
        width: 16rem;

        span {
            color: var(--primary-color);
            text-align: center;
            font-family: Open Sans;
            font-size: 0.6875rem;
            width: 100%;
            display: block;
            text-transform: uppercase;
        }
    }

    &-efficiency-level {
        position: absolute;
        top: 1rem;
        left: 0.75rem;

        &.details {
            top: 0;
            left: 0;
        }

        div {
            position: relative;
        }

        span {
            position: absolute;
            color: #202020;
            font-size: 10px;
            font-weight: 600;
            top: 50%;
            left: 0.3125rem;
            transform: translate(0, -50%);
        }

        svg {
            path {
                fill: inherit;
            }
        }
    }

    &-image {
        grid-area: img;
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
        width: 12.5rem;
        height: 6.25rem;
    }

    &-details {
        grid-area: details;

        .type {
            width: 100%;
            display: block;
            font-family: Open Sans;
            font-size: 0.75rem;
            font-weight: 500;
            text-align: center;
            color: $neutral-600;
            margin-bottom: 0.25rem;
        }

        .name {
            font-family: Open Sans;
            font-size: 0.875rem;
            text-align: center;
            color: $neutral-800;
            width: 100%;
            display: block;
            margin-bottom: 0.5rem;
        }

        .link {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 1rem;

            .MuiLink-root {
                font-family: Open Sans;
                font-size: 0.75rem;
                font-weight: 400;
                color: $neutral-800;
                text-transform: uppercase;
            }
        }
    }

    &-price {
        padding: 0.5rem 0.75rem;
        border-radius: 5px;
        border: 1px solid $neutral-200;

        grid-area: price;
        display: flex;
        background-color: #fafafa;

        &.selected {
            border: 1px solid var(--primary-color);

            .equipment-card-price-separator {
                background-color: var(--primary-color);
            }
        }

        &-left,
        &-right {
            width: 50%;
            display: flex;
            flex-direction: column;
        }

        &-left {
            .price {
                font-family: Open Sans;
                font-size: 0.875rem;
                font-weight: 600;
                color: var(--primary-color);
            }
            .text {
                font-family: Open Sans;
                font-size: 0.75rem;
                font-weight: 500;
                color: $neutral-600;
            }
        }

        &-right {
            .price {
                font-family: Open Sans;
                font-size: 0.875rem;
                font-weight: 600;
                color: $neutral-800;
            }
            .text {
                font-family: Open Sans;
                font-size: 0.75rem;
                font-weight: 500;
                color: $neutral-600;
            }
        }

        &-separator {
            width: 1px;
            height: 100%;
            background-color: $neutral-200;
            margin: 0 1rem;
        }
    }

    &-summary {
        border-radius: 0.3125rem;
        border: 1px solid $neutral-200;
        padding: 1rem 0.75rem;
        display: flex;
        margin-bottom: 0.5rem;

        .image {
            width: 3.5625rem;
            height: 3.5625rem;
            margin-right: 1.5rem;
        }

        .details {
            width: 60%;
            display: flex;
            flex-direction: column;

            .type {
                color: $neutral-600;
                font-family: Open Sans;
                font-size: 0.875rem;
                margin-bottom: 0.5rem;
            }

            .name {
                color: $neutral-800;
                font-family: Open Sans;
                font-size: 0.875rem;
                margin-bottom: 0.5rem;
            }

            .dimensions {
                color: $neutral-800;
                font-family: Open Sans;
                font-size: 0.75rem;
                font-weight: 400;
            }
        }
    }
}
