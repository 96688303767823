@use "../global/_global-dir" as *;

.generic-title {
  font-weight: $font-weight-semibold;
  font-size: 1rem;
  line-height: normal;

  .red-line {
    height: 3px;
    background-color: var(--primary-color);
    width: 4.375rem;
    margin-top: 0.5rem;
  }
}
