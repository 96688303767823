@use "../../../@efz/_efz-dir" as *;
@use "../../../global/_global-dir" as *;

.cards-title {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  font-size: 1rem;
  line-height: 1.375rem;

  h3 {
    font-size: 1.125rem;
  }
}

.cards-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.spv-cards-carousel {
  margin-inline: auto;
  width: 100%;

  .slick {
    &-slide {
      display: flex;
      justify-content: center;
    }
    &-list,
    &-dots {
      max-width: calc(892px + 3rem); // 446px of each card * 2 + gaps
      margin-inline: auto;
      @media screen and (max-width: 1200px) {
        max-width: calc(446px + 3rem); // 446px of one card + gaps
      }
    }
    &-list {
      padding-top: 1.625rem;
      margin-top: -1.625rem;
    }
    &-dots {
      position: relative;
      bottom: 0;
    }
    &-disabled {
      svg path {
        fill: $neutral-300;
        transition: all 0.3s ease;
      }
    }
    &-arrow {
      svg {
        width: 2rem;
        height: 2rem;
      }
    }
    &-prev,
    &-next {
      width: auto;
      height: auto;
      padding: 1rem;
      z-index: 40;
      top: calc(50% - 0.813rem); // to make up for the top padding

      &:before {
        display: none;
      }
    }
    &-prev {
      left: 0;
    }
    &-next {
      right: 0;
    }
  }
}
.slick-scrollbar {
  --width-value: 33%;
  margin-top: 30px;
  -webkit-appearance: none;
  width: 100%;
  height: 1rem;
  background: rgba(67, 66, 68, 0.1);
  outline: none;
  border-radius: 10px;
  max-width: calc(65.25rem - 1.5rem); // 348px of each card * 3
  margin-inline: auto;
  box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;

  &:hover {
    opacity: 1;
  }
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: var(--width-value);
    height: 1rem;
    background: $silver-chalice;
    cursor: pointer;
    border-radius: 10px;
  }
  &::-moz-range-thumb {
    width: var(--width-value);
    height: 1rem;
    background: $silver-chalice;
    cursor: pointer;
    border-radius: 10px;
  }
}

.cards-information {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  font-size: 0.875rem;
  line-height: 1.188rem;

  span {
    margin-left: 0.5rem;
  }
}

.simulation-card-container {
  display: flex;
  // height: 32rem;
  height: 97.4124809741248vh;
  width: 28rem;
  transform-style: preserve-3d;
  perspective: 1000px;
  margin: 1rem;
  border: 1px solid transparent;

  .card-lec {
    width: 85.375rem !important;
  }

  @media (min-height: 767.98px) {
    height: 32rem;
  }
  &.card-lec {
    @extend .simulation-card-container;
    width: 34rem;
    height: 26rem !important;
  }

  .solution-button {
    display: flex;
    justify-content: center;
    margin-top: 1.5625rem;
    flex-grow: 1;
    align-items: flex-end;

    &-details {
      @extend .solution-button;
      position: absolute;
      bottom: 2rem;
      left: 0;
      width: 100%;
    }

    button {
      height: 40px;
      padding: 10px 18px;
      border: 2px solid var(--primary-color);
      // background-color: var(--disabled-color);
    }
    .MuiButton-outlined.Mui-disabled {
      border: 2px solid var(--primary-color);
      background-color: var(--primary-color-bleached);
      color: var(--text-color);
    }
  }

  &.flipped {
    .card-front {
      transform: rotateY(180deg);
    }
    .card-back {
      transform: rotateY(0deg);
    }
  }

  &.selected {
    @extend .simulation-card-container;

    border-color: var(--primary-color);
    border-radius: $border-radius;
  }

  .card-front,
  .card-back {
    display: flex;
    position: absolute;
    height: 100%;
    width: 100%;
    background: var(--paper-color);
    box-shadow: 0px 2px 4px 0px #00000040;
    padding: 1.625rem;
    border-radius: $border-radius;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: transform ease 500ms;

    &.selected {
      box-shadow: inset 0px 0px 0px 2px var(--primary-color);
    }
  }

  .card-front {
    flex-flow: column nowrap;
    z-index: 2;
    transform: rotateY(0deg);
    /* front tile styles go here! */

    .eye-icon {
      path {
        fill: var(--primary-color);
      }
    }
  }

  .card-back {
    transform: rotateY(-180deg);
    /* back tile styles go here! */
    padding-top: 1.75rem;

    .card-back-icon button {
      position: absolute;
      top: 0;
      right: 0;
      // top: -2%;
      // left: -30%;
    }
  }

  .card-image {
    display: flex;
    justify-content: center;
    height: 10rem;

    img {
      align-self: center;
    }
    &-aqs {
      display: flex;
      justify-content: center;
      margin-bottom: 1.607rem;
      img {
        width: auto;
        height: 100%;
      }
    }
  }

  .card-body {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    padding: 0;
  }

  .card-row {
    width: 100%;
    display: flex;
    align-items: center;

    &-label {
      font-size: 0.825rem;
      font-weight: $font-weight-semibold;
      color: $silver-chalice;
      text-transform: uppercase;
      line-height: 1rem;
      text-align: center;

      hr {
        margin-top: 0.5rem !important;
        margin-bottom: 0.75rem;
      }
    }

    &-no-solution-label {
      color: $neutral-800;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 600;
      text-align: center;
    }

    &-break {
      height: 3rem;
      border-right: 1px solid var(--disabled-color);
    }

    .monthly-value {
      font-weight: $font-weight-semibold;
    }

    .middle-box {
      border-left: 1px solid rgba(196, 196, 196, 0.5);
      // border-right: 1px solid rgba(196, 196, 196, 0.5);
    }

    .total {
      .label {
        font-weight: $font-weight-semibold;
      }
    }

    .lec-value {
      width: 33%;

      .solution {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 1rem;

        &-number {
          font-weight: $font-weight-semibold;
        }
      }
    }
  }

  .card-details {
    display: flex;
    flex-flow: column nowrap;

    .card-title {
      font-weight: $font-weight-semibold;
      margin-bottom: 0.3rem;
      color: var(--text-color);
    }

    hr {
      margin: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      width: 70px;
      height: 2px;
      background: var(--primary-color);
      opacity: 1;
    }

    .card-description {
      margin-top: 1rem;
      font-size: 0.825rem;
    }
  }

  .card-back-details {
    @extend .card-details;
    border-bottom: 0;
  }

  .card-values {
    display: flex;
    flex-flow: row nowrap;
    margin-top: 1rem;
    margin-bottom: 1rem;

    .label {
      font-size: 0.825rem;
      font-weight: $font-weight-semibold;
      color: $silver-chalice;
      text-transform: uppercase;
      line-height: 1rem;
      text-align: center;
      margin-bottom: 0.75rem;
    }

    .value-container {
      display: flex;
      flex: 1;
    }
  }

  .number-of-panels-spinner {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-flow: column nowrap;
    padding: 0.5rem;
    justify-content: center;

    .spinner-button {
      min-width: 28px;
      height: 28px;
      width: 28px;
      padding: 5px;

      .MuiButton-label {
        line-height: 0rem;
      }
    }

    .number-of-panels-recommended {
      display: flex;
      justify-content: center;
      font-size: 0.75rem;
      line-height: 0.688rem;
      color: var(--primary-color);
      margin-top: 0.25rem;
      flex-flow: column;
      align-items: center;

      span {
        margin-top: 0.5rem;
      }
    }

    .panel-number {
      font-size: 1.25rem;
    }
  }

  .total-power {
    font-size: 0.875rem;

    &-label {
      font-weight: $font-weight-semibold;
    }
  }

  .solution-investment {
    display: flex;
    flex-grow: 1;
    // flex-shrink: 0;
    flex-flow: column nowrap;
    padding: 0.5rem;
    border-right: 1px solid var(--disabled-color);

    .solution-investment-value,
    .solution-kpis-end-value {
      font-size: 1rem;
      line-height: 1.375rem;
      font-weight: $font-weight-semibold;

      .total {
        font-size: 0.75rem;
      }
    }
  }
  .solution-investment-value {
    font-size: 0.75rem;

    .total {
      font-size: 0.875rem;
    }
  }
  .solution-kpis-end {
    display: flex;
    flex-grow: 1;
    // flex-shrink: 0;
    flex-flow: column nowrap;
    padding: 0.5rem;

    .solution-kpis-end-value {
      font-size: 1rem;
      line-height: 1.375rem;
      font-weight: $font-weight-semibold;
    }
  }
  .solution-kpis {
    @extend .solution-kpis-end;
    border-right: 1px solid var(--disabled-color);

    .solution-kpis-value {
      @extend .solution-kpis-end-value;
    }
  }

  .solution-savings {
    display: flex;
    flex-grow: 1;
    // flex-shrink: 0;
    flex-flow: column nowrap;
    // padding: 0.5rem;
    margin-top: 0.25rem;

    .solution-savings-value {
      display: flex;
      justify-content: center;
      font-weight: $font-weight-semibold;
      font-size: 1rem;
      line-height: 1.375rem;
      color: #35b894;
      text-align: center;
    }
  }
  .solution-estimated-annual-savings {
    @extend .solution-savings;
    border-right: 1px solid var(--disabled-color);

    .solution-estimated-annual-savings-value {
      @extend .solution-savings-value;
    }
  }

  .card-characteristics {
    display: flex;
    // flex-flow: column wrap;
    flex-wrap: wrap;
    width: 100%;

    &-subtitle {
      font-weight: $font-weight-semibold;
      color: #202020;
      margin-top: 1.5rem;
    }
    .card-characteristic {
      display: flex;
      flex-flow: column wrap;
      margin-top: 1rem;
      width: 50%;

      .label {
        font-weight: $font-weight-semibold;
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 0.25rem;
        color: $silver-chalice;
        text-transform: uppercase;
      }

      .value {
        font-size: 14px;
        line-height: 19px;
      }
    }
  }

  .card-button-selector {
    margin: 0 auto;
    width: 10rem;
  }

  &.lec-neighbor {
    height: 18rem !important;

    .card-details {
      border-bottom: 0;
    }

    .card-values {
      flex-flow: column;

      > div {
        display: flex;
      }
      .solution-kpis {
        .solution-kpis-value {
          color: #202020;
          font-style: $font-style-normal;
          font-weight: $font-weight-semibold;
          font-size: 1.125rem;
          line-height: 1.5rem;
          text-align: center;
        }
        .label {
          color: #202020;
          font-style: $font-style-normal;
          font-weight: $font-weight-normal;
          font-size: 0.875rem;
          line-height: 1.5rem;
          text-transform: none;
          text-align: center;
        }
      }

      .value-container:last-of-type .solution-kpis {
        border-right: 0;
      }

      .solution-estimated-annual-savings {
        border-right: 0;
        margin-top: 1.8rem;

        .label {
          padding-bottom: 0.5rem;
          border-bottom: 1px solid var(--disabled-color);
        }
      }
    }
  }

  &.lec-condominum {
    .card-details {
      border-bottom: 0;
      display: flex;
      flex-flow: row;
      justify-content: space-between;

      svg {
        path {
          fill: var(--primary-color);
        }
      }
    }

    .card-front .card-values {
      flex-flow: column;

      .solution-kpis {
        .solution-kpis-value {
          color: #202020;
          font-style: $font-style-normal;
          font-weight: $font-weight-semibold;
          font-size: 1.125rem;
          line-height: 1.5rem;
          text-align: center;
        }
        .label {
          color: #202020;
          font-style: $font-style-normal;
          font-weight: $font-weight-normal;
          font-size: 0.875rem;
          line-height: 1.5rem;
          text-transform: lowercase;
          text-align: center;
        }
      }

      .card-first-row {
        display: flex;
        flex-flow: row;
        justify-content: center;

        .value-container-image {
          margin-right: 2.5rem;
          align-items: center;
        }

        .value-container {
          display: flex;
          flex: 0;
          align-items: center;

          .solution-kpis {
            padding: 0 1.25rem;
          }
        }
      }

      .value-container:last-of-type .solution-kpis {
        border-right: 0;
      }

      .solution-estimated-annual-savings,
      .solution-investment {
        border-right: 0;
        margin-top: 1.5rem;

        .label {
          padding-bottom: 0.5rem;
          border-bottom: 1px solid var(--disabled-color);
        }
      }
    }

    .card-back {
      display: flex;
      flex-flow: column;

      .card-values {
        display: flex;
        flex-flow: row wrap;
        margin-top: 0;

        .value-container {
          flex: 0 1 50%;
          margin-bottom: 1rem;

          .solution-kpis {
            border: 0;
            .solution-kpis-value {
              color: #202020;
              font-style: $font-style-normal;
              font-weight: $font-weight-normal;
              font-size: 0.875rem;
              line-height: 1.188rem;
              text-align: initial;
            }
            .label {
              font-weight: $font-weight-semibold;
              font-size: 0.75rem;
              line-height: 1rem;
              text-transform: uppercase;
              color: $silver-chalice;
              text-align: initial;
              margin-bottom: 0.25rem;
            }
          }
        }
      }
    }
  }

  .back-button-details {
    display: flex;
    .label-button-details {
      font-size: 12px;
    }
    svg {
      path {
        fill: var(--primary-color);
      }
    }
  }
}

.simulation-warning-limit-space {
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  align-items: center;
  margin-top: 2rem;
  border: 1.5px solid var(--primary-color);
  border-radius: 5px;
  box-sizing: border-box;
  background: var(--paper-color);
  padding: 1.5rem 6.5rem;
  max-width: 80%;
  margin: 2rem auto;

  &-title {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
    color: var(--primary-color);
    font-size: 1rem;
    line-height: 1.375rem;

    h3 {
      margin: 0;
    }

    &-icon {
      margin-right: 0.5rem;
    }
  }

  &-body {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    font-size: 0.875rem;
    line-height: 1.188rem;
  }
}

.simulation-list-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-top: 2rem;

  > div {
    min-width: 60%;
  }

  .simulation-list-before-title {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    font-style: $font-style-normal;
    font-weight: $font-weight-normal;
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: center;
  }

  .simulation-list-title {
    display: flex;
    justify-content: center;
    font-size: 1rem;
    line-height: 1.375rem;
    margin-bottom: 1.5rem;
    font-weight: $font-weight-normal;
  }

  .simulation-list-item {
    display: flex;
    font-size: 0.875rem;
    line-height: 1.188rem;
    padding: 0.75rem 0;
    border-top: 1px solid var(--disabled-color);
    justify-content: space-between;
    font-weight: $font-weight-normal;

    &:last-child {
      border-bottom: 1px solid var(--disabled-color);
    }
  }
}

.simulation-no-potential {
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;

  .simulation-no-potential-icon {
    display: flex;
    justify-content: center;
    margin-top: 3.375rem;
  }

  .simulation-no-potential-info {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
    font-size: 1rem;
    line-height: 1.375rem;
  }

  .simulation-no-potential-actions {
    display: flex;
    justify-content: center;
    margin-top: 2.1875rem;
  }
}

.solution-card-container.no-height {
  height: calc(42rem - 6.0938rem);
}

.solution-card-container,
.simulation-card-container,
.solution-card-container-sizing {
  @extend .simulation-card-container;

  height: 47rem;

  .card-front {
    &.no-shadow {
      box-shadow: none;
    }

    &.no-solution {
      background-color: $neutral-100;
    }
    .card-row {
      &:first-of-type {
        margin-top: 1.25rem !important;
      }

      & .font-higlight {
        font-weight: $font-weight-semibold;
        font-size: 0.875rem;
        line-height: 1.188rem;
        color: $silver-chalice;
        text-transform: uppercase;
      }
    }

    .card-info-gama-title {
      margin-top: 0.75rem;
      margin-bottom: 2.516rem;
    }

    .card-info-gama {
      left: 2.938rem;
      font-weight: $font-weight-semibold;
      font-size: 0.625rem;
      line-height: 0.875rem;
      color: $silver-chalice;
      text-transform: uppercase;
      // position: absolute;
    }

    .card-info-title {
      font-weight: $font-weight-semibold;
      font-size: 0.875rem;
      line-height: 1.188rem;

      .green-warning {
        margin-left: 0.25rem;
        path {
          fill: #38cd44;
        }
      }
    }

    .sub-label {
      font-size: 0.75rem;
    }

    .card-image {
      height: unset;
      margin-bottom: 1.607rem;
    }

    .card-row-border {
      // border: 1px solid #c4c4c4;
      box-sizing: border-box;
      // border-radius: 5px;
      margin-bottom: 1rem;
      height: 17.465753424657535vh;

      &:last-of-type {
        margin-bottom: 1.25rem !important;
      }
    }

    .card-row-full {
      display: flex;
      flex-flow: column;
      justify-content: center;
      // height: 37.29071537290715vh;
      height: fit-content;
      // padding-bottom: 1.625rem;
    }

    .card-row-half {
      display: flex;
      flex-flow: column;
      justify-content: center;
      // height: 37.29071537290715vh;
      height: fit-content;
      padding-bottom: 1.625rem;

      .card-info-gama-title {
        margin-bottom: 1rem;
      }

      .card-image {
        margin-bottom: 0;
      }
    }

    .panel-number {
      font-size: 1rem;
      line-height: 1.75rem;
    }

    .right-box {
      padding-right: 1rem;
      border-right: 1px solid rgba(196, 196, 196, 0.5);
    }

    .solution-savings-title {
      font-weight: $font-weight-semibold;
      font-size: 0.875rem;
      line-height: 1.188rem;
    }
  }

  .card-back {
    .card-characteristics-product {
      margin-top: 1.5rem;
      font-weight: $font-weight-semibold;
      font-size: 0.875rem;
      line-height: 1.375rem;
      color: var(--primary-color);

      .gama-title {
        font-weight: $font-weight-semibold;
        font-size: 0.625rem;
        line-height: 0.875rem;
        color: $silver-chalice;
        text-transform: uppercase;
        display: flex;
        align-items: center;
      }

      hr {
        margin-top: 0.5rem;
        width: 100%;
        border: 1px solid #e5e5e5;
        background-color: unset;
        height: unset;
      }
    }
    .gama-title {
      font-weight: $font-weight-semibold;
      font-size: 0.625rem;
      line-height: 0.875rem;
      color: $silver-chalice;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
    }
    .card-back-tabs {
      width: 100%;
      background-color: transparent;
      color: #000000;
      min-height: 46px;
      display: flex;
      box-shadow: unset;
      margin-top: 0.5rem;

      .MuiTabs-root {
        display: flex;
        height: 100% !important;
        .MuiTabs-flexContainer {
          height: 100%;
          margin: 0 -0.5rem;

          .card-back-tab {
            display: flex;
            align-items: center;
            min-height: 46px;
            font-size: 0.875rem;
            min-width: 25% !important;
            padding: 0 !important;
            min-height: unset !important;
            text-transform: inherit;
            border-bottom: 2px solid #e5e5e5;
            margin: 0 0.5rem;

            @media (min-width: 600px) {
              min-width: unset !important;
            }

            &-selected {
              @extend .card-back-tab;
              color: var(--primary-color);
              border-bottom: 2px solid var(--primary-color);
            }
          }
        }
        .MuiTabs-indicator {
          display: none;
        }
      }
    }
  }
  &-sizing {
    .card-front {
      background-color: $neutral-100;
    }
    .sizing-button {
      margin-right: auto;

      button {
        position: relative;
        overflow: hidden;

        &::before {
          content: "";
          position: absolute;
          background-color: #fff;
          width: 100%;
          height: 100%;
          z-index: -1;
        }
      }
    }
  }

  // options dropdown styles styles
  .action-options {
    .actions-dropdown-toggle {
      color: var(--text-color-secondary);
      display: flex;
      width: 1.5rem;
      height: 1.5rem;
      box-sizing: border-box;
      border-radius: 3.5px;
      text-align: center;
      border: -1px solid var(--text-color-secondary) !important;
      background-color: unset;
      padding: 0;
      justify-content: center;

      &:hover,
      &:active,
      &[aria-expanded="true"] {
        svg {
          rect {
            stroke: var(--primary-color) !important;
          }
          circle {
            fill: var(--primary-color) !important;
          }
        }
      }

      &:focus {
        box-shadow: unset !important;
      }
    }

    .drop-menu {
      border: 1px solid var(--primary-color);
      background-color: var(--paper-color);
      position: relative;
    }
    .dropdown-item {
      padding: 0.5rem;
      z-index: 1;
      text-transform: uppercase;

      h5 {
        margin: 0;
      }

      svg {
        path {
          fill: var(--primary-color);
        }
      }

      &:hover {
        background-color: var(--primary-color);

        h5 {
          color: var(--paper-color) !important;
        }

        svg {
          path {
            fill: var(--paper-color);
          }
        }

        .label {
          font-weight: $font-weight-semibold;
          font-size: 0.75rem;
          margin: 0;
          flex-grow: 0;
        }

        .value {
          font-size: 1.125rem;
        }
      }

      &:active {
        background-color: var(--primary-color) !important;
        border-color: var(--primary-color) !important;

        svg path {
          fill: var(--paper-color);
        }
      }

      &-disabled {
        cursor: auto;
        pointer-events: none;
        // background-color: var(--disabled-color);
        color: var(--disabled-color) !important;

        svg {
          path {
            fill: var(--disabled-color);
          }
        }
        &:hover {
          background-color: var(--disabled-color);
        }
      }
    }
  }
}

.simulation-battery-warning,
.simulation-tariff-warning {
  display: flex;
  // justify-content: center;
  border: 1px solid #3885cd;
  background-color: #f1f8ff;
  border-radius: 3px;
  width: 100%;
  padding: 1rem;
  font-size: 0.875rem;

  svg {
    margin-right: 0.75rem;
  }

  &.green {
    border: 1px solid #38cd44;
    background-color: #f1fff4;

    .green-warning {
      path {
        fill: #38cd44;
      }
    }
  }
}
.solution-card-housing-panels,
.simulation-card-housing-panels,
.solution-card-container-sizing {
  @extend .simulation-card-container;

  height: 47rem;

  .card-section {
    display: flex;
    width: 100%;

    label {
      margin-bottom: 0.5rem;
    }

    .recomended-label {
      font-size: 0.75rem;
      color: var(--primary-color);
      text-transform: uppercase;
    }

    &-images {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      margin: 2rem 0;

      .card-image {
        margin: 0;
      }
    }

    &-actual {
      max-width: 25%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .actual-equipment {
        display: flex;
        margin-bottom: 0.75rem;
        &-name {
          color: #000000;
          font-size: 0.875rem;
          line-height: 19px;
          font-weight: $font-weight-semibold;
          margin-right: 0.75rem;
        }
        &-number {
          @extend .actual-equipment-name;
          font-weight: $font-weight-normal;
        }
      }
    }

    &-divisor {
      height: 100%;
      border-right: 1px dashed #e5e5e5;
      margin: 0 2rem;
    }

    &-future {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .future-equipments {
        display: flex;
        margin-bottom: 1rem;

        .card-info-title {
          margin-bottom: 0.5rem;
        }

        .number-of-panels-spinner {
          padding: 0;
        }
      }

      .aditional-investments {
        display: flex;
        flex-direction: column;

        .investment {
          display: flex;
          font-size: 0.75rem;

          .monthly-value {
            margin-right: 0.5rem;
            font-weight: $font-weight-semibold;
          }
        }

        .total {
          display: flex;
          font-size: 0.875rem;
          line-height: 19px;
          color: #202020;
          margin-top: 0.625rem;

          .number {
            font-weight: $font-weight-semibold;
          }
        }
      }
    }

    &-hr {
      width: 100;
    }

    &-final-solution {
      max-width: 25%;
      margin-right: 2.5rem;
      .final-solution-label {
        color: var(--primary-color);
        font-size: 0.875rem;
        line-height: 22px;
        font-weight: $font-weight-semibold;
        margin-bottom: 0.5rem;
      }
      .final-solution-equipment {
        display: flex;
        margin-bottom: 0.375rem;
        &-name {
          color: #000000;
          font-size: 0.875rem;
          line-height: 19px;
          font-weight: $font-weight-semibold;
          margin-right: 0.75rem;
        }
        &-number {
          @extend .actual-equipment-name;
          font-weight: $font-weight-normal;
        }
      }
    }

    &-infos {
      display: flex;
      flex-direction: column;
    }
    & .aqs-height {
      height: 90px;
    }
  }

  .card-back-tabs {
    width: 100%;
    background-color: transparent;
    color: #000000;
    min-height: 46px;
    display: flex;
    box-shadow: unset;
    margin-top: 0.5rem;

    .MuiTabs-root {
      display: flex;
      height: 100% !important;
      .MuiTabs-flexContainer {
        height: 100%;
        margin: 0 -0.5rem;

        .card-back-tab {
          display: flex;
          align-items: center;
          min-height: 46px;
          font-size: 0.875rem;
          min-width: 25% !important;
          padding: 0 !important;
          min-height: unset !important;
          text-transform: inherit;
          border-bottom: 2px solid #e5e5e5;
          margin: 0 0.5rem;

          @media (min-width: 600px) {
            min-width: unset !important;
          }

          &-selected {
            @extend .card-back-tab;
            color: var(--primary-color);
            border-bottom: 2px solid var(--primary-color) !important;
          }
        }
      }
      .MuiTabs-indicator {
        display: none;
      }
    }
  }
  .edit-button {
    margin-right: auto;

    button {
      padding: 0 0.625rem;

      &:hover {
        background-color: transparent;
      }
      svg {
        height: 1.5rem !important;
        width: 1.5rem !important;
      }
    }
  }
}
.add-bundle {
  &-card {
    display: flex;
    width: 90%;
    max-width: 910px;
    margin: 0.75rem 0rem 2rem;
  }
  &-title,
  &-description {
    font-size: 0.875rem;
    line-height: 1.188rem;
  }
  &-title {
    font-weight: 600;
    margin-bottom: 1.5rem;
  }
  &-description {
    font-weight: 400;

    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }
}
.bundle {
  &-item {
    flex-grow: 1;
    max-width: 50%;
    &:not(:last-of-type) {
      border-right: 1px dashed $neutral-200;
      padding-right: 4.563rem;
      margin-right: 3.625rem;
      // max-width: calc(536px + 4.563rem);
    }
    &-title {
      color: var(--primary-color);
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.25rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid $neutral-200;
      margin-bottom: 1.5rem;
      width: 100%;
    }
    .MuiPaper-root {
      max-width: 525px;
    }
    .simulation-card-container {
      height: 38rem;
      width: 24.75rem;
      margin-left: 0;
      .card-front {
        &.selected {
          box-shadow: 0px 2px 4px 0px #00000040;
        }
        .solution-button {
          display: none;
        }
      }
      .card-back {
        &.selected {
          box-shadow: 0px 2px 4px 0px #00000040;
        }
        .solution-button-details {
          display: none;
        }
      }
    }
    .card-aqs-center {
      width: 50%;
    }
  }
  &-product-card {
    margin-bottom: 1.5rem;

    .MuiBox-root {
      position: relative;

      button {
        position: absolute;
        bottom: 1.5rem;
        right: 2rem;
        width: 8.875rem;
      }
    }
    &-title {
      margin: 0rem;
      padding: 0rem;
      font-size: 1.125rem;
      line-height: 1.75rem;
      color: $neutral-800;
    }
    &-checked,
    &-unchecked {
      margin-top: 2rem;
      margin-bottom: 0.5rem;
      display: flex;
      align-items: center;

      svg {
        margin-right: 0.5rem;
        width: 0.938rem;
      }
    }
    &-checked {
      color: $success-600;
    }
    &-unchecked {
      color: $error-600;
    }
  }
  &-aqs-questions {
    .MuiPaper-root.MuiDialog-paper {
      width: 70vw !important;
      max-width: 869px !important;
    }
    &-container {
      padding: 1rem 2rem;

      .deactived .MuiSelect-select {
        color: $silver-chalice;
      }
      .input-container .MuiOutlinedInput-root.Mui-disabled {
        background-color: $neutral-100;
      }
    }
    &-title {
      display: flex;
      justify-content: space-between;
      .close-icon {
        margin-right: -1.25rem;
        margin-top: -0.25rem;
      }
    }
    &-warning {
      display: flex;
      align-items: center;
      margin-top: 0.5rem;
      color: #3885cd;
      font-size: 0.75rem;
      line-height: 1rem;
    }
    h2 {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.375rem;
      padding-bottom: 0;
      margin-bottom: 0.3rem;
    }
    hr {
      margin: 0;
      height: 2px;
      width: 70px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      opacity: 1;
      background-color: var(--primary-color);
      margin-left: 1.5rem;
    }
    .Mui {
      &OutlinedInput-root {
        max-width: 18.75rem;
      }
      &DialogContent-root {
        padding: 1rem 1.5rem;
      }
    }
    .input-container {
      margin-bottom: 1.5rem;

      &:first-of-type {
        margin-top: 0.5rem;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    button {
      margin: auto;
    }
  }
}

@media screen and (max-width: 992px) {
  .add-bundle-card {
    .MuiBox-root {
      flex-direction: column;
    }
    .MuiCardMedia-root {
      align-self: center;
      margin-bottom: 1rem;
    }
    .MuiCardActions-root {
      margin-top: 1rem;
      align-self: center;

      button {
        padding: 1rem 1.25rem;
      }
    }
  }
  .bundle {
    &-item {
      max-width: none;
      width: 100%;
      &:not(:last-of-type) {
        border-right: none;
        padding-right: 0;
        margin-right: 0;
        margin-bottom: 2rem;
        max-width: none;
      }
    }
  }
}
