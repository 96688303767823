@use "../../../global/_global-dir" as *;

.collective-info-dialog {
    h5 {
        font-weight: $font-weight-semibold;
        font-size: 14px;
        line-height: 22px;
        color: var(--primary-color);
        padding-bottom: 0.5rem;
        border-bottom: 1px solid #e5e5e5;
        margin-bottom: 1rem;
    }

    .info-features-box {
        margin-bottom: 1rem;
    }

    .info-feature {
        display: flex;
        flex-flow: column nowrap;
        row-gap: 0.75rem;

        div {
            display: flex;
            align-items: center;
            column-gap: 1rem;
            background: #f5f5f5;
            border: 1px solid #e5e5e5;
            box-sizing: border-box;
            border-radius: 10px;
            padding: 1rem;
            width: 100%;
            height: 3.75rem;

            svg {
                width: 30px;
                justify-content: center;
            }

            span {
                justify-content: flex-start;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
            }

            .receipt-icon,
            .marker-icon {
                path {
                    fill: var(--primary-color);
                }
            }

            .plant-icon {
                rect {
                    stroke: var(--primary-color);
                }
            }
        }
    }

    .info-msg {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 1rem;
    }
}

.collective-info-next-button {
    svg {
        path {
            fill: var(--paper-color) !important;
        }
    }
}

.generic-dialog .MuiDialog-paper {
    max-width: max-content;
}
