@use "../../../global/_global-dir" as *;
@use "../../../pages/products/me";

.garage-form {
    &-title {
        h1 {
            font-size: 1.25rem;
            font-weight: $font-weight-semibold;
            line-height: 27px;
            margin-bottom: 0.5rem;
        }

        hr {
            height: 3px;
            background-color: var(--primary-color);
            width: 70px;
            margin: 0;
        }

        h4 {
            font-size: 0.875rem;
            line-height: 24px;
        }
    }

    .garage-form-floors {
        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .add-button {
                width: 39px;
                height: 39px;
                padding: 0 !important;
                min-width: unset;
                border: 1px solid var(--primary-color);
                border-radius: 4px;

                svg {
                    path {
                        fill: var(--primary-color);
                        stroke: var(--primary-color);
                    }
                }

                &-disabled {
                    @extend .add-button;
                    border: 1px solid var(--disabled-color);

                    svg {
                        path {
                            fill: var(--disabled-color);
                            stroke: var(--disabled-color);
                        }
                    }
                }
            }

            .floor-button {
                font-size: 0.875rem;
                text-transform: none;
                padding: 0.625rem 2rem;
            }
        }

        &-body {
            margin-top: 1rem;
            padding: 2rem 2.625rem;
            background-color: var(--paper-color);
            border: 1px solid #c4c4c4;
            border-radius: 10px;

            .floor-title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: var(--primary-color);
                font-weight: $font-weight-semibold;
                line-height: 22px;
                font-size: 1rem;
                .MuiIconButton-root {
                    padding: 0;

                    &:first-of-type {
                        margin-right: 1rem;
                    }
                }
                .floor-duplicate-button {
                    path {
                        fill: var(--primary-color);
                        stroke: var(--primary-color);
                    }
                    &-disabled {
                        path {
                            fill: var(--disabled-color);
                            stroke: var(--disabled-color);
                        }
                    }
                }

                .floor-delete-button {
                    path {
                        fill: var(--primary-color);
                    }
                    &-disabled {
                        path {
                            fill: var(--disabled-color);
                        }
                    }
                }
            }

            .input-container-label {
                @extend .input-container;
                margin: 0px !important;
                align-items: center;
            }
            .input-container-row {
                @extend .input-container;
                flex-direction: row !important;
                width: 100% !important;
                align-items: center;
                justify-content: space-between;
                column-gap: 1rem;

                .MuiOutlinedInput-root {
                    // max-width: 33.333%;

                    .MuiInputAdornment-root {
                        color: var(--disabled-color);
                    }
                }

                > :first-child {
                    flex: 0 1rem 60%;
                }

                > :last-child {
                    flex: 1 1;
                }

                .label-info {
                    display: flex;
                    align-items: center;
                    color: #3885cd;
                    font-size: 0.75rem;
                    line-height: 16px;

                    svg {
                        fill: #3885cd;
                        width: 1rem;
                        height: 1rem;
                        margin-right: 4px;
                    }
                }
            }

            .input-container-row,
            .input-container {
                .input-label {
                    font-weight: $font-weight-normal;

                    svg {
                        margin-left: 0.5rem;
                    }
                }

                .input-label-bold {
                    @extend .input-label;

                    svg {
                        margin-left: 0.5rem;
                    }
                }
                hr {
                    margin: 0.625rem 0 1.5rem 0;
                }
            }
        }
    }

    .floor-selected {
        background-color: var(--primary-color-bleached) !important;
    }
    .section-inputs-container {
        width: 100%;
    }

    form {
        display: flex;
        flex-flow: column;
        align-items: center;
    }
}
