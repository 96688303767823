@use "../../../bootstrap.scss" as bootstrap;
@use "../../../global/_global-dir" as *;

.simulation-form {
  @include bootstrap.media-breakpoint-down(md) {
    flex-wrap: wrap;
    row-gap: 2rem;
  }

  .simulation-card {
    margin-right: 2rem;
    transform-style: preserve-3d;
    perspective: 1000px;

    &:last-of-type {
      margin-right: 0;
    }

    &-body {
      position: relative;
      width: 312px;
      height: 440px;
      // background: var(--paper-color);
      text-align: center;

      .card {
        &-info {
          font-weight: $font-weight-normal;
          font-size: 0.75rem;
          line-height: 1rem;
          color: #a3a3a3;
          position: relative;
          width: 100%;

          span {
            position: absolute;
            right: 0;
            width: 77px;
          }
        }

        &-img {
          margin-bottom: 1rem;
        }

        &-title {
          font-weight: $font-weight-semibold;
          font-size: 1rem;
          line-height: 1.375rem;

          p {
            margin-bottom: 0.5rem;
          }

          .card-link {
            font-weight: $font-weight-normal;
            font-size: 0.75rem;
            line-height: 1rem;
            text-transform: uppercase;
            text-decoration: underline;
            cursor: pointer;
          }
        }

        &-option {
          margin-bottom: 1rem;
          font-weight: $font-weight-semibold;
          font-size: 0.75rem;
          line-height: 1.25rem;
          align-items: center;
          display: flex;
          justify-content: center;

          .radio-button span {
            font-weight: $font-weight-normal;
            font-size: 0.75rem;
            line-height: 1.25rem;
          }
        }

        &-power {
          min-height: 1.125rem;
          font-weight: $font-weight-normal;
          font-size: 0.75rem;
          line-height: 1rem;
          margin-bottom: 1rem;
          text-align: center;
          justify-content: space-between;

          & .recommended {
            font-weight: $font-weight-semibold;
            color: #3885cd;
          }

          span {
            margin-right: 1rem;

            &:last-of-type {
              margin-right: 0;
            }
          }
        }
        &-solution {
          margin-bottom: 1rem;

          .buttons > div {
            display: flex;
            justify-content: space-between;
          }

          .button-container {
            margin-right: 0.5rem;

            &:last-of-type {
              margin-right: 0;
            }
            button {
              text-transform: none;
              flex-flow: nowrap;
              box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.08);
              border-radius: 0.313rem;
              padding: 0 0.75rem;
              &:not(.Mui-disabled) {
                background: var(--paper-color);
                color: var(--text-color);
                border: 1px solid var(--primary-color);

                &.selected {
                  background: var(--primary-color-bleached);
                }
              }
            }
          }

          button {
            font-weight: $font-weight-normal;
            font-size: 0.75rem;
            line-height: 1rem;
            text-transform: none;
            flex-flow: nowrap;
            border: 1px solid #c4c4c4;
            box-sizing: border-box;
            border-radius: 0.313rem;
            padding: 0 0.75rem;
            height: 32px;
            margin-bottom: 0.625rem;

            &.selected {
              color: var(--text-color);
              border: 1px solid var(--primary-color-bleached);
              box-sizing: border-box;
              border-radius: 0.313rem;
            }
          }

          .button-price {
            flex-flow: column;
            min-height: 40px;
            display: flex;
            justify-content: center;

            p {
              margin-bottom: 0;
            }
          }

          .price {
            font-weight: $font-weight-normal;
            font-size: 1.125rem;
            line-height: 1.563rem;
          }
        }
        &-action {
          display: flex;
          align-items: center;
          justify-content: center;
          width: calc(100% - 2.5rem); // 100% - padding of body
          position: absolute;
          bottom: 0;

          margin-bottom: 1rem;

          button {
            font-weight: $font-weight-normal;
            font-size: 1rem;
            line-height: 1.375rem;
            text-transform: none;
            flex-flow: nowrap;
            background: var(--paper-color);
            color: var(--text-color);
            border: 1px solid var(--primary-color);
            box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.08);
            border-radius: 0.313rem;
            padding: 0 0.75rem;
            height: 42px;

            &.selected {
              background: var(--primary-color-bleached);
            }
          }
        }
      }

      &-front {
        position: absolute;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        transition: transform ease 500ms;
        transform: rotateY(0deg);
        background: var(--paper-color);
      }

      &-back {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        transition: transform ease 500ms;
        transform: rotateY(-180deg);
        background: var(--paper-color);

        .navigation {
          display: flex;
          margin-bottom: 1rem;

          .back-button-details {
            svg {
              path {
                fill: var(--primary-color);
              }
            }
          }
        }

        .body-description {
          font-size: 0.875rem;
          line-height: 19px;
          margin-bottom: 10px;
        }

        .body-kpis {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 10px;

          .solution-kpi {
            margin-bottom: 5px;
            display: flex;
            flex-flow: column wrap;
            align-items: flex-start;
            width: 100%;

            label {
              color: #a3a3a3;
              font-weight: $font-weight-semibold;
              line-height: 16px;
              font-size: 0.75rem;
              margin-bottom: 0.25px;
              text-align: start;
            }

            &-value {
              color: #202020;
              font-size: 0.75rem;
              line-height: 16px;
              text-align: left;
            }
          }
        }
      }

      &.flipped {
        .simulation-card-body-front {
          transform: rotateY(180deg);
        }
        .simulation-card-body-back {
          transform: rotateY(0deg);
        }
      }

      .simulation-card-body-front,
      .simulation-card-body-back {
        position: absolute;
        padding: 1.25rem 1.25rem 0 1.25rem;
        left: 0;
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 0.625rem;
        width: 100%;
        height: 100%;

        &.recommended {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-bottom: transparent 1px solid;
        }

        &.selected {
          border: 1px solid var(--primary-color);
        }
      }
    }

    &-recommended {
      background: var(--primary-color-bleached);
      border: 1px solid var(--primary-color);
      box-sizing: border-box;
      border-radius: 0px 0px 0.625rem 0.625rem;
      text-align: center;
      padding: 0.375rem 1rem;

      p {
        font-weight: $font-weight-normal;
        font-size: 0.75rem;
        line-height: 1rem;
        margin-bottom: 0;
        color: var(--text-color);
      }
    }
  }
}

.simulation-form-info {
  margin-top: 2rem;

  p {
    font-weight: $font-weight-normal;
    font-size: 0.875rem;
    line-height: 1.188rem;

    &:last-of-type {
      font-weight: $font-weight-semibold;
      font-size: 0.75rem;
      line-height: 1.375rem;
    }
  }
}

.offline-solution {
  background-color: white;
  margin-top: 2rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.625rem;
  border: 1px solid #e5e5e5;
  padding: 1rem 2rem;

  &-info {
    display: flex;
    flex-flow: column nowrap;
    min-width: 100%;

    &-firstLine {
      border-bottom: 1px solid #e5e5e5;

      .offline-solution-info-title {
        color: var(--primary-color);
        font-weight: $font-weight-semibold;
      }
    }

    &-content {
      display: flex;
      flex-flow: column nowrap;
      margin-top: 1rem;
      row-gap: 0.625rem;

      .offline-solution-info-subtitle {
        font-weight: $font-weight-semibold;
      }
    }
  }
}
