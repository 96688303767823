.icon {
    position: relative;

    .resend {
        position: absolute;
        top: -0.625rem;
        right: 0.125rem;
        background-color: white;
    }
}
