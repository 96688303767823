@use "../global/_global-dir" as *;

.processes-container {
  .csv-download-button {
    // margin-bottom: 1rem;
    // align-self: flex-end;
    min-width: 10rem;
    // height: auto !important;
    padding: 0 !important;
    cursor: pointer;
    height: 100%;

    &:hover,
    &:active,
    &:focus {
      background-color: unset !important;
    }

    .button {
      &-enabled {
        svg {
          path {
            fill: var(--primary-color);
          }
        }
      }

      &-disabled {
        svg {
          path {
            fill: var(--disabled-color);
          }
        }
      }
    }

    .button-text {
      text-decoration: underline;
      font-size: 0.875rem;
    }
  }

  .top-search-bar {
    display: flex;
    flex-flow: row nowrap;
    //margin-bottom: 1rem;
    /*     padding: 0.5rem 15px; */
    height: 55px;

    @media screen and (max-width: 768px) {
      max-width: 720px !important;
    }

    .filters-bar-button {
      span {
        font-size: 0.9rem;
        white-space: nowrap;
        min-width: initial;
      }

      &:hover {
        color: var(--primary-color);
      }
    }

    #outlined-processes-search {
      height: 19px;
      padding: 8px 12px;
      min-width: 150px;
      width: 450px;
    }

    .loading-component {
      min-width: 0;
    }

    .MuiTypography-root {
      white-space: nowrap;
    }

    .MuiInputBase-root {
      background-color: var(--paper-color);
    }

    .toggle-filters-button,
    .reset-filters-button {
      min-width: 10rem;
      height: auto !important;
      padding: 0 !important;
      cursor: pointer;

      .cross-icon {
        path {
          fill: var(--primary-color);
        }
      }

      @media screen and (max-width: 768px) {
        min-width: auto;
      }

      &:hover,
      &:active,
      &:focus {
        background-color: unset !important;
      }

      span {
        text-decoration: underline;
        font-weight: $font-weight-normal;
        font-size: 0.875rem;
        line-height: 1.188rem;
        text-transform: uppercase;

        &:hover,
        &:active,
        &:focus {
          background-color: unset !important;
        }
      }

      &.menu-item-arrow-up svg {
        transform: rotate(180deg);
      }
    }
  }
  .action-options {
    svg.disabled {
      rect {
        stroke: var(--disabled-color);
      }
      circle {
        fill: var(--disabled-color);
      }
    }
    &-disable {
      pointer-events: none;
    }
    .actions-dropdown-toggle {
      color: var(--text-color-secondary);
      display: flex;
      width: 1.5rem;
      height: 1.5rem;
      box-sizing: border-box;
      border-radius: 3.5px;
      text-align: center;
      border: -1px solid var(--text-color-secondary) !important;
      background-color: unset;
      padding: 0;
      justify-content: center;

      &:hover,
      &:active,
      &[aria-expanded="true"] {
        svg {
          rect {
            stroke: var(--primary-color) !important;
          }
          circle {
            fill: var(--primary-color) !important;
          }
        }
      }

      &:focus {
        box-shadow: unset !important;
      }
    }

    .drop-menu {
      border: 1px solid var(--primary-color);
      background-color: var(--paper-color);
      position: relative;
    }
    .dropdown-item {
      padding: 0.5rem 1rem;
      z-index: 1;
      text-transform: uppercase;

      svg {
        path {
          fill: var(--primary-color);
        }
      }

      &:hover {
        background-color: var(--primary-color);

        h5,
        h6 {
          color: var(--paper-color) !important;
        }

        svg {
          path {
            fill: var(--paper-color);
          }
        }

        .label {
          font-weight: $font-weight-semibold;
          font-size: 0.75rem;
          margin: 0;
          flex-grow: 0;
        }

        .value {
          font-size: 1.125rem;
        }
      }

      &:active {
        background-color: var(--primary-color) !important;
        border-color: var(--primary-color) !important;

        svg path {
          fill: var(--paper-color);
        }
      }

      &-disabled {
        cursor: auto;
        pointer-events: none;
        // background-color: var(--disabled-color);
        color: var(--disabled-color) !important;

        svg {
          path {
            fill: var(--disabled-color);
          }
        }
        &:hover {
          background-color: var(--disabled-color);
        }
      }
    }
  }
}

.processes-filters-dropdown {
  .process-filter-input {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;

    &.disabled {
      opacity: 0.5;
    }
  }

  .date-filter-input {
    column-gap: 10px;
  }
  border-top: none;
  border-bottom: 1px solid $silver-light;
  margin-bottom: 1rem;
  background-color: var(--background-color);
  width: 100%;

  .submit-button-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }

  label {
    font-weight: $font-weight-bold;
    margin-bottom: 5px;
  }

  .MuiButton-root {
    padding: 6px 15px;
    font-size: 1rem;
    font-weight: $font-weight-normal;
    height: 40px;
    color: var(--text-color);
    border: 2px solid var(--primary-color);
  }

  .MuiInputBase-root {
    background-color: var(--paper-color);
  }

  .MuiInputBase-input {
    font-size: 0.875rem;
  }

  .MuiInputAdornment-root {
    color: var(--disabled-color);
    font-size: 0.875rem;
    padding-left: 0.875rem;
  }

  .multiline-autocomplete {
    .MuiOutlinedInput-root {
      // Outside box
      min-height: 40px !important;
      height: fit-content !important;
    }
    .MuiAutocomplete-inputRoot {
      // Array of options
      padding: 7.5px 39px 7.5px 0.75rem !important;
      display: flex;
      align-items: center;
    }

    .MuiAutocomplete-input {
      // Search placeholder
      padding: 0 !important;
    }
  }

  .selected-chips {
    max-width: 90%;
    display: flex;
    flex-wrap: wrap;
    &:not(:empty) {
      padding-left: 0.75rem;
    }
  }

  .multi-input {
    position: relative;

    .MuiOutlinedInput-root {
      // Outside box
      min-height: 40px !important;
      height: fit-content !important;
      display: flex;
      flex-wrap: wrap;
    }

    input {
      min-width: 20%;
      max-width: 90%;
    }

    &-button {
      position: absolute;
      right: 0;
    }
  }

  .selected-chip {
    border-radius: 3px !important;
    margin-right: 0.5rem !important;
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
    height: 25px !important;
    border: 1px solid var(--primary-color);
    background-color: var(--paper-color);
    color: var(--text-color);

    .MuiChip-label {
      // Text of the choice
      padding-right: 0.375rem;
      padding-left: 0.5rem;
      font-size: 0.625rem;
    }

    .MuiIconButton-label {
      width: 8px;
    }

    .MuiChip-deleteIcon {
      // delete X on choice
      margin: 0 0 0 -6px;
    }
  }
}

.generic-dialog {
  .MuiDialog-paper {
    min-width: 750px;
    @media only screen and (max-width: 768px) {
      min-width: 410px;
    }
  }

  .MuiDialogTitle-root {
    padding: 2.125rem 3.75rem 0 3.75rem !important;
  }

  .MuiDialogContent-root {
    padding: 1rem 3.75rem 2rem 3.75rem;
    // overflow-y: hidden;

    @media only screen and (max-width: 768px) {
      padding: 1.5rem;
    }
  }

  .MuiDialogActions-root {
    padding: 0;
    margin-bottom: 2.5rem;
  }

  &-header {
    font-weight: $font-weight-semibold;
    font-size: 1rem;
    .red-line {
      height: 3px;
      background-color: #ee162d;
      width: 4.375rem;
    }
  }

  .generic-dialog-close {
    position: absolute;
    right: 0;
    margin: 1.5rem;
    border: none;
  }
}

.process-details-dialog {
  .process-details {
    display: inherit;

    &-header {
      display: inline-block;
      max-width: 100%;
      font-weight: $font-weight-semibold;
      margin-bottom: 4px;
      font-size: 0.875rem;
      color: $silver-chalice;
      text-transform: uppercase;
    }

    &-section-header {
      display: flex;
      color: var(--primary-color);
      padding-bottom: 8px;
      font-size: 0.875rem;
      font-weight: $font-weight-semibold;
      border-bottom: 1px solid #e5e5e5;
      margin-top: 0.5rem;
      margin-bottom: 1rem;

      &:first-of-type {
        margin-top: 0;
      }
    }

    &-values {
      font-size: 0.875rem !important;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-subtitle {
      font-size: 0.875rem;
      font-weight: $font-weight-semibold;
      color: #202020;
      margin-bottom: 1rem;
    }

    .process-detail {
      flex: 1 0 32%;
      width: inherit;
      margin-right: 0.5rem;
    }

    &.details-general-info > .row:last-child {
      .process-details-values {
        font-size: 0.875rem;

        .MuiInputBase-root {
          font-size: 0.875rem;
        }
      }

      .process-details-comments {
        .MuiInput-underline {
          &:before {
            content: unset;
          }

          &.Mui-disabled:before {
            border-bottom-style: none;
          }
        }

        .Mui-disabled {
          color: var(--text-color);
          -webkit-text-fill-color: var(--text-color);
        }

        textarea {
          padding: 0 !important;
        }

        fieldset {
          border: none;
        }
      }
    }
    .custom-tooltip {
      margin-left: 0.125rem;
      margin-bottom: 0.125rem;
    }

    & .card-kpis {
      height: auto;
      flex: 1;

      .label {
        font-weight: $font-weight-semibold;
        font-size: 0.75rem;
        margin: 0;
        flex-grow: 0;
      }

      .value {
        font-size: 1.125rem;

        .MuiCircularProgress-root {
          height: 22px !important;
          width: 22px !important;
        }
      }
    }

    .generic-dialog-action {
      display: flex;
      align-items: center;
    }
  }
  .inverters-divider {
    border-top: 1px dashed $silver-light;
    margin-top: 0
  }
}

.process-clients {
  .process-client {
    position: relative;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    max-width: 16.875rem;
    width: 40%;
    margin-right: 1rem;

    &-input {
      @extend .process-client;
      min-height: 5.375rem;
    }

    .process-client-header {
      font-weight: $font-weight-semibold;
      font-size: 0.875rem;
      line-height: 1.188rem;
      margin-bottom: 0.5rem;
    }

    .input-container {
      .Mui-error {
        position: absolute;
        bottom: 0rem;
      }
    }
  }
}

.popper-auto {
  z-index: 1000 !important;
}

.multiselect-menu-item {
  .MuiCheckbox-root {
    padding: 0px;
  }
  .MuiTypography-root {
    padding-left: 16px;
    font-size: 0.875rem;
  }
}

.select-disabled-input {
  .MuiSelect-select {
    color: $silver-chalice;
    opacity: 1;
  }
}
