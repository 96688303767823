@use "../../../global/_global-dir" as *;

.characterization-form {
    .input-container {
        max-width: 270px;
        margin-right: 2.5rem;
        word-break: break-word;

        .MuiOutlinedInput-input {
            background-color: var(--paper-color);
        }
    }

    .characterization-alert {
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
    }
}

.characterization-accordion {
    display: flex;
    flex-flow: column;
    width: 100%;

    .MuiAccordionSummary-root {
        background-color: var(--paper-color);
        border: 1px solid rgba(196, 196, 196, 0.5);
        box-sizing: border-box;
        border-radius: 3px;
        min-height: auto;

        &.Mui-expanded {
            min-height: auto;
        }
        .MuiAccordionSummary-content {
            margin: 0.875rem 0;
        }
        .MuiIconButton-root {
            padding: 0 12px;
        }
    }

    &-header {
        display: flex;
        flex-flow: row;
        background: #e5e5e5;
        border: 1px solid rgba(32, 32, 32, 0.2);
        box-sizing: border-box;
        border-radius: 0.313rem;
        padding: 0.5rem 2.5rem;

        div {
            flex: 1 0 25%;
            font-style: $font-style-normal;
            font-weight: $font-weight-semibold;
            font-size: 0.875rem;
            line-height: 1.188rem;
        }
    }

    &-body {
        display: flex;
        flex-flow: row;
        padding: 0.5rem 2.5rem;
        background: #ffffff;
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 0.313rem;
        margin-top: 0.5rem;

        div {
            display: flex;
            margin: auto;
            margin-left: 0;
            flex: 1 0 25%;
            font-style: $font-style-normal;
            font-weight: $font-weight-normal;
            font-size: 0.875rem;
            line-height: 1.188rem;
        }
    }

    .input-sub-container {
        max-width: 190px;
        display: flex;
        flex-flow: column;

        .MuiInputAdornment-positionEnd {
            color: $silver-chalice;
            font-size: 0.875rem;
            justify-content: flex-end;
            margin-right: 1rem;
        }
    }
}
