@use "../bootstrap.scss" as bootstrap;
@use "../global/_global-dir" as *;

/*Footer Styles*/

.app-footer {
    min-height: 2.813rem; // 2 + 0.813 font-size
    color: $light-gray;
    font-size: 0.813rem;
    position: relative;
    bottom: 0;
    width: 100%;
    text-align: center;
    z-index: 1;

    display: flex;
    align-items: center;

    @include bootstrap.media-breakpoint-down(lg) {
        font-size: 0.7rem;
        padding: 0.5rem 0;
    }

    .footer-policy-links {
        position: absolute;
        right: 0;
        margin-right: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 1025px) {
            margin-right: 0.5rem;
        }

        span {
            text-decoration: underline;
            margin-right: 1rem;
            cursor: pointer;

            @media (max-width: 1025px) {
                font-size: 0.4rem;
                margin-right: 0.2rem;
                padding: 0.5rem 0;
            }
        }
    }
}
