@use "../bootstrap.scss" as bootstrap;
@use "../global/_global-dir" as *;

.login-svg-bg {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-flow: row nowrap;
    flex: 1;

    .login-svg-left {
        display: flex;
        width: 50vw;
        align-items: flex-start;
        justify-content: flex-start;

        svg {
            height: 100%;
            width: auto;
        }
    }

    .login-svg-right {
        display: flex;
        width: 50vw;
        align-items: flex-start;
        justify-content: flex-end;

        svg {
            height: 100%;
            width: auto;
        }
    }
}

/*Login Styles*/
.app-main-login {
    background-color: var(--background-color);
    display: flex;
    flex-flow: column;
    flex-wrap: nowrap;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
}

// Login window and svg's container
.login-top-section {
    position: absolute;
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    width: 100%;
    align-items: center;
    justify-content: center;
    min-height: 100%;
}

// Center login container
.app-login-container {
    @include display-flex(flex, column, nowrap);
    align-items: center;
    justify-content: center;
    z-index: 1000;

    & .loader-view {
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        top: 60%;
        bottom: 50%;
        z-index: 2;
    }
}

// Whole center login window
.login-content {
    @include display-flex(flex, column, nowrap);
    background-color: var(--paper-color);
    box-shadow: $shadow-xl;
    max-width: 700px;
    min-width: 500px;

    @include bootstrap.media-breakpoint-down(md) {
        min-width: 70vw;
    }

    @include bootstrap.media-breakpoint-down(sm) {
        min-width: 90vw;
    }
}

// Header section containing logo
.login-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem 0;

    @include bootstrap.media-breakpoint-down(lg) {
        padding: 2rem 0;
    }

    svg {
        width: 191px;
        height: 64.813px;
    }
}

// Body section containing form
.login-body {
    display: flex;
    flex-flow: column nowrap;
    padding: 0 3rem 3rem 3rem;

    @include bootstrap.media-breakpoint-down(sm) {
        padding: 0 2.5rem 1.5rem 2rem;
    }

    h4 a {
        //default value for go back link
        font-weight: $font-weight-light;
    }

    .link-back-login {
        margin-top: 10px;
    }
}

// Text in login window
.login-body-text {
    text-align: center;

    h4 {
        font-weight: $font-weight-light;
        color: var(--text-color);
        pointer-events: none;
    }
}

// Fields and bttn
.login-form {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;

    h4 a {
        //default value for forgot your password link
        font-weight: $font-weight-light;
    }

    label {
        color: var(--text-color);
        font-size: 0.875rem;
        margin-bottom: 0.5rem;
        font-weight: $font-weight-bold;
    }

    .change-button {
        position: absolute;
        top: 0.625rem;
        right: -4rem;
        color: #3885CD;
        font-size: 0.75rem;
        cursor: pointer;
    }
}

.registry-content {
    @include display-flex(flex, column, nowrap);
    background-color: var(--paper-color);
    box-shadow: $shadow-xl;
    max-width: 700px;
    min-width: 700px;

    @include bootstrap.media-breakpoint-down(sm) {
        min-width: 70vw;
    }

    @include bootstrap.media-breakpoint-down(xs) {
        min-width: 90vw;
    }
}

.app-version {
    position: absolute;
    right: 0px;
    font-size: xx-small;
    opacity: 0.1;
}
