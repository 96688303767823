@use "../../../global/_global-dir" as *;

.typology-container {
    section {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
    }

    .typology-card {
        margin: auto 0.75rem;
        border-width: 0.09375rem;
    }

    .title-card {
        font-style: normal;
        font-weight: $font-weight-semibold;
        font-size: 1rem;
        line-height: 1.375rem;
        text-align: center;
        margin-bottom: 1rem;

        p:last-of-type {
            font-size: 0.875rem;
            line-height: 1.188rem;
            font-weight: $font-weight-normal;
        }
    }

    .active {
        @extend .title-card;
        margin-bottom: auto;
        background: var(--primary-color-bleached);
        border: 0.09375rem solid var(--primary-color);
        box-sizing: border-box;
        border-radius: 10px;
        color: var(--text-color);
        svg {
            path {
                fill: var(--primary-color);
            }
        }
        svg.multiple {
            path {
                stroke: var(--primary-color);
                opacity: 0.75;
                fill: inherit;
            }
        }
    }

    .disabled {
        color: #c4c4c4;
        background: #f5f5f5;
        border: 1px solid #dcdcdc;
        box-sizing: border-box;
        border-radius: 10px;
    }

    .MuiCardActionArea-root {
        height: 220px;
        width: 257px;
        display: flex;
        flex-flow: column nowrap;
        max-width: 315px;
        background-repeat: no-repeat;
        flex: 1;
    }
}
