@use "../../../global/_global-dir" as *;

.adaptation-form {
  padding: 0 20px;
  display: flex;
  justify-content: center;
  gap: 20px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  #sectionOptions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-family: "Open Sans", sans-serif; /**/
  }

  #sectionTitle {
    flex: 100%;
    font-weight: 400;
    font-size: 16px;
    margin: 20px 20px 0 20px;
  }

  .cardOption {
    box-sizing: border-box;
    width: 312px;
    padding: 20px;
    margin: 20px;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    background: #ffffff;
  }

  #sectionOptions p:not(.numberPlaces, .divInfo > p, .totalBudget > div) {
    margin: 0;
    font-size: 12px;
  }

  .textBold {
    font-weight: 600;
  }

  .flex_SpaceBetween {
    display: flex;
    justify-content: space-between;
  }

  .textCenter {
    text-align: center;
  }

  .cardButton {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      font-weight: $font-weight-normal;
      font-size: 1rem;
      line-height: 1.375rem;
      text-transform: none;
      flex-flow: nowrap;
      background: var(--paper-color);
      color: var(--text-color);
      border: 1px solid var(--primary-color);
      box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.08);
      border-radius: 0.313rem;
      padding: 0 0.75rem;
      height: 42px;

      &.selected {
        background: var(--primary-color-bleached);
      }
    }
  }

  #sectionOptions hr {
    border: none;
    border-top: 1px solid #e5e5e5;
    margin: 16px 0;
  }

  .numberPlaces {
    font-weight: 400;
    font-size: 70px;
    color: #a3a3a3;
    margin: 12px 0;
    text-align: center;
  }

  .divInfo > p {
    margin: 12px 0 0 0;
    font-size: 12px;
  }

  .totalBudget {
    margin-top: 12px;

    .monthly-fee {
      font-size: 14px;
      font-weight: 600;

      & :last-child {
        font-weight: 400;
      }
    }

    .total-fee {
      font-size: 16px;
      font-weight: 600;
    }
  }

  /* .budget-title {
    font-weight: 600;
    color: #a3a3a3;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-size: 0.75rem;
  } */

  /**/
  .buttonSelected {
    background: var(--primary-color-bleached);
  }

  .cardSelected {
    border: 1px solid var(--primary-color);
  }

  // Start QC-157
  .optionCardWrapper {
    display: flex;
    flex-direction: column;
    max-width: 383px;
    width: 100%;

    .optionCard {
      box-sizing: border-box;
      max-width: 383px;
      width: 100%;
      border: 1px solid #e5e5e5;
      border-radius: 10px;
      cursor: pointer;

      p {
        margin: 0;
      }

      &.optionCardSelected {
        border: 1px solid #3885cd;
      }

      .optionCardTop {
        padding: 20px;
        background: #ffffff;
        color: #202020;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        display: flex;
        flex-direction: column;

        &.optionCardTopSelected {
          background: #f1f8ff;
          color: #3885cd;
        }
      }

      .optionCardBottom {
        padding: 20px;
        background: #79838b;
        color: #ffffff;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &.optionCardBottomSelected {
          background: #3885cd;
          color: #ffffff;
        }

        table {
          font-size: 12px;

          td {
            padding: 2px;
          }

          th {
            text-align: center;
          }

          td:nth-child(2),
          td:nth-child(3) {
            text-align: center;
          }

          table > tr > td:first-child {
            width: 55%;
          }

          table > tr:last-child {
            font-weight: 600;
          }
        }
      }

      .dashed-line {
        border: none;
        height: 1px;
        background: #000;
        background: repeating-linear-gradient(90deg, #79838b, #79838b 6px, transparent 6px, transparent 12px);
      }

      .solid-line {
        color: #c4c4c4;
      }

      .cardTopTitle {
        text-align: center;
        font-weight: 600;
        font-size: 16px;
      }

      .cardTopSubtitle {
        font-weight: 600;
        font-size: 14px;
        height: fit-content;
        margin: 0;
      }
      .cardBottomTitle {
        text-align: center;
        font-size: 14px;
        font-weight: 600;
      }

      .veichelsNumber {
        min-width: 13%;
        display: flex;
        justify-content: center;
        font-weight: 600;
        font-size: 32px;
        color: #79838b;

        &.veichelsSelected {
          color: #3885cd;
        }
      }

      .charginOptionContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      .chargingOptionTitle {
        font-weight: 600;
        font-size: 12px;
      }

      .floorContainer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
      }
    }

    .infoContainer {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      padding: 10px 5px;
      color: #3885cd;
      font-size: 12px;

      svg {
        width: 75px;
        height: 75px;
      }
    }
  }

  // End QC-157
}
