@use "../global/_global-dir" as global;
@use "../@efz/colors" as *;

.multiple-selectable-elements {
    display: flex;
    flex-wrap: wrap;
}

.selectable-element-with-check {
    border-radius: 5px;
    margin: 0.75rem 1rem 1rem 0;
    padding: 0.6rem 1rem;
    cursor: pointer;
    &.checked {
        background: var(--primary-color-bleached);
        border: 1.5px solid var(--primary-color);
    }
    &.unchecked {
        background: #ffffff;
        border: 1px solid #c4c4c4;
    }

    &.error {
        border: 1px solid #d32f2f;

        .MuiCheckbox-root {
            svg {
                rect {
                    stroke: #d32f2f;
                }
            }
        }
    }

    span {
        padding-left: 0;
        padding-top: 0;
        padding-bottom: 0;
    }

}

.selectable-element-with-switch {
    display: flex;
    flex-direction: column;
    margin-top: 0.75rem;

    &-switch {
        margin-left: -0.5625rem;
        margin-right: 0.5rem;
    }

    &-icon {
        margin-right: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
    }

    &-name {
        // font-family: 'Open Sans';
        font-size: 0.875rem;
        font-weight: 400;
        color: #202020;
    }

    &-period {
        display: flex;
        flex-direction: column;
        margin-left: 3.625rem;

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        .label {
            color: #202020;
            font-size: 0.875rem;
            margin-top: 0.5rem;
            font-weight: 600;
        }

    }

    &-quantity {
        margin-left: 3.625rem;
        .label {
            color: #202020;
            font-size: 0.875rem;
            margin-top: 0.5rem;
            font-weight: 600;
        }
    }

    &-line {
        border: 1px solid #DCDCDC;
        width: 100%;
    }
}
