@use "../global/_global-dir" as *;

.prevent-nav-dialog {
    .MuiDialog-paperWidthSm {
        max-width: 500px;
    }

    .close-button {
        position: absolute;
        right: 0;
        margin-right: 1rem;
        margin-top: 0.5rem;

        svg {
            width: 1rem;
            height: 1rem;
        }
    }

    .MuiDialogTitle-root {
        display: flex;
        justify-content: center;

        h2 {
            color: var(--text-color);
        }
    }

    .MuiDialogContentText-root {
        padding: 0 1rem;
    }
}
