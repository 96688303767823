@use "../../../global/_global-dir" as *;

.distance-form {
    .container-subtitle {
        font-weight: $font-weight-normal;
        font-size: 0.875rem;
        line-height: 1.5rem;
        text-align: center;
        margin-bottom: 1rem;
    }

    .warning-info {
        margin-top: -0.5rem;
    }

    &.condominium-private {
        flex-flow: column nowrap;
        .input-container {
            background: var(--paper-color);
            border: 1px solid #e5e5e5;
            box-sizing: border-box;
            border-radius: 0.625rem;
            padding: 1.5rem 2.5rem;

            span {
                font-weight: $font-weight-semibold;
                font-size: 0.875rem;
                line-height: 1.188rem;
            }
        }
    }
}

.distance-type-container {
    section {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        margin-bottom: 1.5rem;
    }

    .distance-type-card {
        margin: auto 0.75rem;
        border-width: 0.09375rem;
    }

    .title-card {
        font-style: normal;
        font-weight: $font-weight-semibold;
        font-size: 1rem;
        line-height: 1.375rem;
        text-align: center;

        p:last-of-type {
            font-size: 0.875rem;
            line-height: 1.188rem;
            font-weight: $font-weight-normal;
            margin: 0;
        }
    }

    .active {
        @extend .title-card;
        margin-bottom: auto;
        background: var(--primary-color-bleached);
        border: 0.09375rem solid var(--primary-color);
        box-sizing: border-box;
        border-radius: 10px;
        color: var(--text-color);
        svg {
            path {
                fill: var(--primary-color);
            }
        }
    }

    .disabled {
        color: #c4c4c4;
        background: #f5f5f5;
        border: 1px solid #dcdcdc;
        box-sizing: border-box;
        border-radius: 10px;
    }

    .MuiCardActionArea-root {
        height: 51px;
        width: 270px;
        display: flex;
        flex-flow: column nowrap;
        max-width: 315px;
        background-repeat: no-repeat;
        flex: 1;
    }
}

.measurement-info-dialog-title {
    h2 {
        font-weight: $font-weight-semibold;
        font-size: 1rem;
        line-height: 22px;
    }

    h2:after {
        content: "";
        width: 70px;
        height: 3px;
        background: var(--primary-color);
        display: block;
        position: relative;
        left: 0px;
        bottom: 0px;
    }
}

.measurement-info-dialog-container {
    section {
        flex: 1 1 33%;
        margin-right: 1rem;

        &:last-of-type {
            margin-right: 0;
        }

        .info-img {
            text-align: center;
        }

        .info-title {
            margin-top: 1rem;
            font-weight: $font-weight-semibold;
            font-size: 0.875rem;
            line-height: 1.188rem;
            color: $silver-chalice;
            padding-bottom: 0.75rem;
            border-bottom: 1px solid #e5e5e5;
        }

        .info-body {
            font-weight: $font-weight-normal;
            font-size: 0.75rem;
            line-height: 1.25rem;
        }
    }
}
