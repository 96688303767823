@use "../../../global/_global-dir" as *;

.simulation-generation {
    .simulation-text {
        &-principal {
            font-weight: $font-weight-semibold;
        }
        &-secondary {
            font-size: 0.875rem;
            margin-top: 1.5rem;
        }
    }
}
