@use "../global/_global-dir" as *;

.cookie {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 2;
    background: rgba(196, 196, 196, 0.25);

    .cookie-banner {
        position: absolute;
        bottom: 0;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        background: #f5f5f5;
        border: 1px solid #c4c4c4;
        border-radius: 10px 10px 0 0;

        &-content {
            max-width: 600px;
            margin-right: 2.5rem;
            margin-top: 2rem;
            margin-bottom: 2rem;

            .cookie-banner-title {
                display: flex;
                column-gap: 0.5rem;
                align-items: center;
                font-weight: $font-weight-semibold;
                line-height: 21.79px;
                margin-bottom: 1.375rem;
                font-size: 1rem;
            }

            .cookie-banner-text {
                font-size: 0.875rem;
                line-height: 19.07px;
            }
            .link {
                color: var(--text-color);
                text-decoration: underline;
                cursor: pointer;
            }
        }

        &-button {
            background-color: var(--primary-color);
            color: white;
        }
        &-button:hover {
            background-color: var(--primary-color-dark);
        }
    }
}

.cookie-consent {
    width: 100%;
    height: 100%;
    z-index: 1001;

    &-authenticated {
        display: flex;
    }

    &-not-authenticated {
        position: fixed;
        top: 0;
        left: 0;
    }

    &-header {
        width: 100%;
        height: 70px;

        display: flex;
        align-items: center;
        justify-content: flex-start;

        background-color: #f5f5f5;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);

        span {
            font-size: 1.125rem;
            font-weight: $font-weight-semibold;
            color: var(--primary-color);
            line-height: 25px;
        }

        .close-cookie {
            margin: 0 2rem 0 2rem;
            span {
                path {
                    fill: var(--primary-color);
                }
            }
        }
    }

    &-body {
        height: calc(100% - 70px);
        background-color: $body-bg;
        display: flex;
        justify-content: center;
        overflow-y: scroll;

        &-content {
            width: 80%;
            margin-top: 3rem;
        }
    }
}

.consent-use-conditions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.consent-title {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 2rem;

    span {
        font-size: 1.25rem;
        font-weight: $font-weight-semibold;
        padding-bottom: 0.5rem;
    }

    hr {
        opacity: 1;
        width: 70px;
        background: var(--primary-color);
        height: 3px;
        margin: 0;
    }
}

.consent-section {
    margin-bottom: 2rem;

    &-title {
        display: flex;
        flex-flow: column nowrap;
        margin-bottom: 19px;

        span {
            font-size: 0.875rem;
            font-weight: $font-weight-semibold;
            padding-bottom: 0.5rem;
            color: var(--primary-color);
        }

        hr {
            opacity: 1;
            width: 476px;
            background: rgba(196, 196, 196, 0.5);
            border: none;
            height: 1px;
            margin: 0;
        }
    }

    &-content {
        line-height: 19px;
        font-size: 0.875rem;

        .link {
            cursor: pointer;
            text-decoration: underline;
            color: blue;
        }
    }

    .privacy-policy-table {
        th {
            color: white;
            height: 40px;

            background: #6c757d;
            width: 100%;
            font-size: 0.875rem;
            border-radius: 5px 5px 0 0;
            border: 1px solid #c4c4c4;
            box-sizing: border-box;
        }

        td {
            padding: 1rem;
        }

        .first-cell {
            min-width: 200px;
            font-weight: $font-weight-semibold;
            font-size: 0.875rem;
            border: 1px solid #c4c4c4;
            box-sizing: border-box;
        }

        .second-cell {
            line-height: 19px;
            border: 1px solid #c4c4c4;
            box-sizing: border-box;
        }
    }

    .cookie-policy-table {
        .head-row {
            background: #f5f5f5;
            border: 1px solid #c4c4c4;
            box-sizing: border-box;
            border-radius: 5px 5px 0px 0px;
            height: 70px;
            text-align: left;
        }

        .cookie-policy-row {
            background: rgba(245, 245, 245, 0.5);
            border: 1px solid #c4c4c4;
            box-sizing: border-box;
            border-radius: 5px 5px 0px 0px;
        }

        th {
            padding: 1rem;
        }

        td {
            vertical-align: top;
            padding: 1rem;
            width: 166px;
            white-space: pre-wrap;
        }
    }
}

.consent-rights-reserved {
    font-size: 0.875rem;
    line-height: 19px;
    margin-top: 0.5rem;
    margin-bottom: 2.5rem;
}

.consent-updated {
    @extend .consent-rights-reserved;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-bottom: 4rem !important;
}

.privacy-section {
    @extend .consent-section;
    margin-bottom: 1.5rem;

    &:last-of-type {
        margin-bottom: 0;
    }

    &-title {
        @extend .consent-section-title;

        &-first-line {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
        }
    }

    &-content {
        @extend .consent-section-content;

        display: flex;
        line-height: 1.5rem;
    }

    .label-category {
        min-width: 100px;
        text-align: center;
        color: var(--text-color);
    }

    .link {
        color: var(--text-color);
        text-decoration: underline;
    }
}

.privacy-dialog {
    &-actions {
        padding: 0rem 3.75rem;
    }

    &-back-button {
        justify-content: flex-start;
    }
}

.generic-dialog {
    .MuiDialogContent-root {
        padding-top: 1.5rem !important;
    }
    &.cookies-dialog {
        .MuiDialog-paper {
            min-width: 784px;
            max-width: 949px;
        }
        .MuiDialogActions-root {
            margin-top: 1rem;
        }
        min-width: 784px;
    }
}
