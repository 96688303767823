@use "../../../bootstrap.scss" as bootstrap;
@use "../../../global/_global-dir" as *;

.proposal-form {
    .proposal-card-form {
        padding: 24px 48px;
        display: flex;
        flex-flow: row;
        background-color: var(--paper-color);
        display: flex;
        width: 100%;
        justify-content: center;

        @include bootstrap.media-breakpoint-down(md) {
            margin-bottom: 3rem;
        }

        section {
            @include bootstrap.media-breakpoint-down(md) {
                flex: 1 1;
            }

            &:first-of-type {
                border-right: 1px solid #c4c4c4;
                padding-right: 40px;
                margin-right: 40px;
            }

            .card-title {
                font-weight: 600;
                font-size: 20px;
                line-height: 27px;

                h2:after {
                    content: "";
                    width: 70px;
                    height: 3px;
                    background: var(--primary-color);
                    display: block;
                    position: relative;
                    left: 0px;
                    bottom: -0.5rem;
                    margin-bottom: 1.5rem;
                }
            }

            .card-body {
                .data-row {
                    display: flex;
                    flex-flow: row nowrap;
                    min-width: 448px;
                    min-height: 41.5px;
                    border-bottom: 1px dashed rgba(196, 196, 196, 0.5);
                    padding-top: 1rem;
                    padding-bottom: 22px;

                    &:first-of-type {
                        padding-top: 0;
                    }

                    &:last-of-type {
                        border-bottom: 0;
                        padding-bottom: 0;
                    }

                    @include bootstrap.media-breakpoint-down(md) {
                        min-width: 100% !important;
                    }
                }

                .data-description {
                    text-align: left;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    flex: 1 1 40%;
                    font-weight: $font-weight-semibold;
                }

                .data-value {
                    text-align: center;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    flex: 1 1 60%;
                }

                .proposal-row {
                    @extend .data-row;
                    min-width: 376px;

                    .proposal-description {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 19px;
                        flex: 1 1 60%;
                        box-sizing: border-box;
                        border-radius: 5px;
                        padding: 6px 12px;
                        margin-right: 1rem;
                        text-align: center;
                        background: #f5f5f5;
                        border: 1px solid #e5e5e5;
                    }

                    .proposal-value {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 19px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        flex: 0 0 30%;
                        justify-content: center;
                    }
                }

                .proposal-payment {
                    @extend .proposal-row;
                    border-bottom: 0;

                    .proposal-description {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #f1f8ff;
                        border: 1px solid #3885cd;
                    }
                }

                .charger {
                    justify-content: center;
                    display: flex;
                    flex-flow: row;

                    div {
                        display: flex;
                        flex-flow: column;
                        justify-content: center;

                        span {
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
    &-contact {
        max-width: 38.5rem;

        .MuiOutlinedInput-input {
            background: var(--paper-color);
        }
    }

    &-final {
        max-width: 31.25rem;

        .simulation-info {
            padding: 0.875rem 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #f1f8ff;
            color: #3885cd;
            border: 1px solid #3885cd;
            box-sizing: border-box;
            border-radius: 3px;
            width: 100%;
            font-weight: $font-weight-semibold;
            font-size: 1rem;
            line-height: 21.79px;
        }
    }
}
