@use "../bootstrap.scss" as bootstrap;

.opportunities-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  // Efficiency measures list
  .list-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;

    .list-item {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: calc(33% - 2rem);
      max-width: calc(33% - 2rem);
      margin: 0 1rem;

      @include bootstrap.media-breakpoint-down(lg) {
        flex-basis: calc(50% - 2rem);
        max-width: calc(50% - 2rem);
      }

      @media (max-width: 710px) {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
  }
}
