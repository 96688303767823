.icon-style {
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 100%;
    min-width: 100%;
    min-height: 100%;
    justify-content: center;
    align-items: center;
    align-self: center;
    // When absolute positioned
    top: 0;
    left: 0;

    label {
        margin-top: 10px;
    }
}
