@use "../../../global/_global-dir" as *;

.space-container {
    // font-size: 35px;
    section {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        // flex: auto;
    }
    .space-card {
        // filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.08));
        margin: auto 0.75rem;
        border-width: 0.09375rem;
    }

    .space-more-options {
        margin-top: 2rem;
        margin-bottom: 2rem;

        & span {
            display: flex;
            align-items: center;
            cursor: pointer;

            svg {
                path {
                    fill: var(--primary-color);
                }
            }
        }

        & label {
            margin-left: 0.625rem;
            text-transform: uppercase;
            text-decoration: underline;
            cursor: pointer;
        }
    }
    .title-card {
        font-style: normal;
        font-weight: $font-weight-semibold;
        font-size: 1rem;
        line-height: 1.375rem;
        text-align: center;
        margin-bottom: 1rem;
        max-width: min-content;
    }

    .active {
        @extend .title-card;
        margin-bottom: auto;
        background: var(--primary-color-bleached);
        border: 0.09375rem solid var(--primary-color);
        box-sizing: border-box;
        border-radius: 10px;
        color: var(--text-color);
        svg {
            path {
                fill: var(--primary-color);

                &[stroke] {
                    stroke: var(--primary-color);
                }
            }
        }
    }

    .disabled {
        color: #c4c4c4;
        background: #f5f5f5;
        border: 1px solid #dcdcdc;
        box-sizing: border-box;
        border-radius: 10px;
    }

    .MuiCardActionArea-root {
        height: 220px;
        width: 257px;
        display: flex;
        flex-flow: column nowrap;
        max-width: 315px;
        background-repeat: no-repeat;
        flex: 1;
    }
}
